import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { home } from "./translations/home.js";
import { homeout } from "./translations/homeout.js";
import { post } from "./translations/post.js";
import { detail } from "./translations/detail.js";
import { listChecker } from "./translations/listChecker.js";
import { userInfo } from "./translations/userInfo.js";
import { statusCode } from "./translations/statusCode.js";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          ...homeout.en,
          ...home.en,
          ...post.en,
          ...detail.en,
          ...listChecker.en,
          ...userInfo.en,
          ...statusCode.en,
        }
      },
      fr: {
        translations: {
          ...homeout.fr,
          ...home.fr,
          ...post.fr,
          ...detail.fr,
          ...listChecker.fr,
          ...userInfo.fr,
          ...statusCode.fr,

        }
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
