import { notification } from "antd";

const res_req_is = {
  messageSuccess: {
    show: false,
    title: "Success",
    description: "Operation Successful",
  },
  messageError: {
    show: false,
    title: "Error",
    description: "Problem with the request",
  },
  loading: false,
  state_code: "",
  form_reset: false,
  new_checker_uuid: false,
};

//I know not a switch reducer, I know
const res_req_reducer = (state = res_req_is, action) => {
  let newState = state;
  //generic success / failure / loading catch
  // those can be customized by adding specific if under
  if (/ING_/.test(action.type)) {
    newState = Object.assign({}, state, {
      form_reset: false,
      loading: true,
    });
  }

  if (/_SUCCESS/g.test(action.type)) {
    newState = Object.assign({}, state, {
      form_reset: true,
      loading: false,
    });
  }
  if (/_FAILED/g.test(action.type)) {
    newState = Object.assign({}, state, {
      form_reset: false,
      loading: false,
    });
  }

  if ("FORM_RESET_FALSE" === action.type) {
    newState = Object.assign({}, state, {
      form_reset: false,
      new_checker_uuid: false,
    });
  }

  if ("POST_SLACK_FAILED" === action.type) {
    newState = Object.assign({}, state, {
      state_code: "Slack-F-001",
    });
  }

  if ("POST_SLACK_SUCCESS" === action.type) {
    newState = Object.assign({}, state, {
      state_code: "Slack-S-001",
    });
  }
  if ("POST_CHECKER_SUCCESS" === action.type) {
    newState = Object.assign({}, state, {
      loading: false,
      new_checker_uuid : action.payload.uuid_to_check
    });
  }

  return newState;
};

export default res_req_reducer;
