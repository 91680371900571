import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
//import of the func
import auth0Client from "./../auth0.js";
import { Avatar, Layout, Menu, Icon } from "antd";
import { connect } from "react-redux";
//import of the func

const { Sider } = Layout;

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.props.detail_user();
    let defaultSelectedKeys;
    let thisRoute = this.props.location.pathname.replace(/\//g, "");
    // regex to remove all the /
    if (thisRoute.includes("ressource")) {
      defaultSelectedKeys = "4";
    } else if (thisRoute.includes("post")) {
      defaultSelectedKeys = "3";
    } else {
      defaultSelectedKeys = "2";
    }
    this.state = {
      defaultSelectedKeys,
    };
  }

  logOut = () => {
    auth0Client.signOut();
  };

  async componentDidMount() {
    try {
      setInterval(async () => {
        this.props.detail_user();
      }, 60 * 1000);
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <Sider
          style={{ position: "relative" }}
          collapsible
          breakpoint="lg"
          collapsed={this.props.collapsed}
          onCollapse={this.props.onCollapse}
          trigger={null}
          collapsedWidth="0">
          <Menu
            id="Menu"
            className={`${this.props.collapsed && "hidden"}`}
            theme="dark"
            defaultSelectedKeys={this.state.defaultSelectedKeys}
            mode="inline">
            <Menu.Item key="1">
              <Link to="/ressource/">
                <div className="user-info">
                  <div className="user-info-logo">
                    {" "}
                    <Avatar
                      className="user-info-avatar"
                      src={this.props.user.picture}
                    />
                  </div>
                  <div
                    className={`user-info-name ${
                      this.props.collapsed && "hidden"
                    }`}>
                    {this.props.user.name.split("@")[0]}
                  </div>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/">
                <Icon type="unordered-list" />
                <span>{t("list-checker")}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to="/post/">
                <Icon type="plus-circle" />
                <span>{t("add-checker")}</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/ressource/">
                <Icon type="setting" />
                <span>{t("list-settings")}</span>
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item className="logout" key="9" onClick={() => this.logOut()}>
              <Icon type="logout" />
              <span>{t("log-out")}</span>
            </Menu.Item>
          </Menu>
          <Icon
            className="trigger"
            type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
            onClick={this.props.toggle}
          />
        </Sider>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    detail_user: (data) => dispatch({ type: "DETAIL_USER" }),
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    form_reset: state.res_req.form_reset,
    loading: state.res_req.loading,
    messageSuccess: state.res_req.messageSuccess,
    messageError: state.res_req.messageError,
  };
}

let SideNavNoRedux = withRouter((props) => <SideNav {...props} />);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(SideNavNoRedux));
