import auth0Client from "./../auth0.js";
import Notification from "./info_notifications.js";

const API_URL = process.env.REACT_APP_API_URL;

class CAPI {
  getAuthHeader() {
    let headers = {
      Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      id_token: `id_token ${auth0Client.getIdToken()}`,
      "Content-Type": "application/json",
    };
    return headers;
  }

  getUnAuthHeader() {
    let headers = {
      "Content-Type": "application/json",
    };
    return headers;
  }

  async getCheck() {
    try {
      let response = await fetch(`${API_URL}/checker`, {
        headers: this.getAuthHeader(),
      });
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      let checkers = json.data;
      return checkers;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async postCheck(data) {
    try {
      let response = await fetch(`${API_URL}/checker`, {
        method: "POST",
        headers: this.getAuthHeader(),
        body: JSON.stringify(data),
      });
      let json = await response.json();
      Notification.evaluate(json);
      json.status_code = response.status;
      if (response.status === 200) {
        return json;
      } else {
        throw response;
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async deleteCheck(uuid_to_check) {
    let response = await fetch(`${API_URL}/checker/${uuid_to_check}`, {
      method: "DELETE",
      mode: "cors",
      headers: this.getAuthHeader(),
    });
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }

  async deepDetailCheck(uuid_to_check) {
    let response = await fetch(
      `${API_URL}/checker/${uuid_to_check}?deep=True`,
      {
        headers: this.getAuthHeader(),
      }
    );
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }

  async detailCheck(uuid_to_check) {
    let response = await fetch(`${API_URL}/checker/${uuid_to_check}`, {
      headers: this.getAuthHeader(),
    });
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }

  async updateCheck(data) {
    let response = await fetch(`${API_URL}/checker/${data.uuid_to_check}`, {
      method: "POST",
      mode: "cors",
      headers: this.getAuthHeader(),
      body: JSON.stringify(data),
    });
    let json = await response.json();
    console.log(json);
    Notification.evaluate(json);
    json.status_code = response.status;
    if (response.status !== 200) {
      throw response;
    } else {
      return json;
    }
  }

  async getWatch() {
    try {
      let response = await fetch(`${API_URL}/watcher`, {
        headers: this.getAuthHeader(),
      });
      if (response.status !== 200) {
        console.log(response);
      }
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async postWatch(data) {
    try {
      let response = await fetch(`${API_URL}/watcher`, {
        method: "POST",
        mode: "cors",
        headers: this.getAuthHeader(),
        body: JSON.stringify(data),
      });
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async deleteWatch(uuid_to_watch) {
    console.log(uuid_to_watch);
    try {
      let response = await fetch(`${API_URL}/watcher/${uuid_to_watch}`, {
        method: "DELETE",
        mode: "cors",
        headers: this.getAuthHeader(),
      });
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async detailWatch(uuid_to_watch) {
    try {
      let response = await fetch(`${API_URL}/watcher/${uuid_to_watch}`, {
        headers: this.getAuthHeader(),
      });
      let json = await response.json();
      json.status_code = response.status;
      if (response.status !== 200) {
        throw json.error_message;
      }
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async updateWatch(data) {
    let response = await fetch(`${API_URL}/watcher/${data.uuid_to_watch}`, {
      method: "POST",
      mode: "cors",
      headers: this.getAuthHeader(),
      body: JSON.stringify(data),
    });
    let json = await response.json();
    json.status_code = response.status;
    if (response.status !== 200) {
      throw response;
    } else {
      Notification.evaluate(json);
      return json;
    }
  }

  async getNotified() {
    try {
      let response = await fetch(`${API_URL}/notified`, {
        headers: this.getAuthHeader(),
      });
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async detailNotified(uuid_to_notify) {
    try {
      let response = await fetch(`${API_URL}/notified/${uuid_to_notify}`, {});
      if (response.status !== 200) {
        console.log(response);
      }
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async verifyNotified(token) {
    try {
      let response = await fetch(`${API_URL}/notified/${token}`, {
        headers: this.getUnAuthHeader(),
        method: "POST",
        mode: "cors",
      });
      let json = await response.json();
      json.status_code = response.status;
      if (json.status_code !== 200) {
        throw json;
      }
      Notification.evaluate(json);
      return json;
    } catch (e) {
      throw e;
    }
  }

  async inviteNotified(uuid_to_notify) {
    try {
      let response = await fetch(
        `${API_URL}/invite-notified/${uuid_to_notify}`,
        {
          headers: this.getAuthHeader(),
          method: "POST",
          mode: "cors",
        }
      );
      let json = await response.json();
      json.status_code = response.status;
      if (json.status_code !== 200) {
        throw json;
      }
      Notification.evaluate(json);
      return json;
    } catch (e) {
      throw e;
    }
  }

  async getSlackFromCode(obj) {
    try {
      let response = await fetch(`${API_URL}/notified/slack/`, {
        headers: this.getAuthHeader(),
        method: "POST",
        mode: "cors",
        body: JSON.stringify(obj),
      });
      // the 409 is returned if the code already exist
      if (response.status == 409) {
        console.log("code already sent");
        throw response.message;
      }
      if (response.status !== 200 && response.status !== 501) {
        console.log("code already sent");
        throw response.message;
      }
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async deleteNotified(uuid_to_notify) {
    let response = await fetch(`${API_URL}/notified/${uuid_to_notify}`, {
      method: "DELETE",
      mode: "cors",
      headers: this.getAuthHeader(),
    });
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }

  async detailUser() {
    let response = await fetch(`${API_URL}/user/`, {
      headers: this.getAuthHeader(),
    });
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }

  async updateDashboardLayout(data) {
    let response = await fetch(`${API_URL}/user/update_dashboard`, {
      method: "POST",
      mode: "cors",
      headers: this.getAuthHeader(),
      body: JSON.stringify(data),
    });
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }

  async getSignedUrl(uuid_to_s, r_for) {
    let url;
    if (r_for === "failure") {
      url = `${API_URL}/get_signed_url_failure/${uuid_to_s}`;
    }
    if (r_for === "seo_report") {
      url = `${API_URL}/get_signed_url_seo_report/${uuid_to_s}`;
    }
    let response = await fetch(url, {
      headers: this.getAuthHeader(),
    });
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }

  async getInfoAboutRoute(newRoute) {
    try {
      let data = {
        new_route: new URL(newRoute).href,
      };
      let response = await fetch(`${API_URL}/info_about_route/`, {
        method: "POST",
        mode: "cors",
        headers: this.getAuthHeader(),
        body: JSON.stringify(data),
      });
      let json = await response.json();
      json.status_code = response.status;
      Notification.evaluate(json);
      return json;
    } catch (e) {
      console.log(e);
    }
    return;
  }

  async requestSSLCheck(uuid_to_check) {
    let response = await fetch(`${API_URL}/request_ssl_check/${uuid_to_check}`, {
      method: "POST",
      mode: "cors",
      headers: this.getAuthHeader(),
    });
    let json = await response.json();
    json.status_code = response.status;
    Notification.evaluate(json);
    return json;
  }
}

const API = new CAPI();

export default API;
