import React, { Component } from "react";
import { Layout } from "antd";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import CreateCheckerModal from "./../containers/CreateCheckerModal.js";

import SideNav from "./../containers/SideNav.js";
//import of the layout
import CreateItem from "./CreateItem.js";
import UpdateItem from "./UpdateItem.js";
import DetailItem from "./DetailItem.js";
import ListItems from "./ListItems.js";
import DetailNotified from "./DetailNotified";
import ListRessources from "./ListRessources";
import DetailWatcher from "./DetailWatcher.js";
import SlackAuth from "./SlackAuth.js";
import LHViewer from "./LHViewer.js";

class Home extends Component {
  state = {
    collapsed: true,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <SideNav
            toggle={this.toggle}
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          />

          <Switch>
            <Route
              path="/notified/:token"
              render={(props) => <DetailNotified {...props} {...this.props} />}
            />
            <Route
              path="/watcher/:uuid_to_watch"
              render={(props) => <DetailWatcher {...props} {...this.props} />}
            />
            <Route
              path="/ressource/"
              render={(props) => <ListRessources {...props} {...this.props} />}
            />
            <Route
              path="/update/:uuid_to_check"
              render={(props) => <UpdateItem {...props} {...this.props} />}
            />
            <Route
              path="/detail/:uuid_to_check"
              render={(props) => <DetailItem {...props} {...this.props} />}
            />
            <Route
              path="/slack/:code"
              render={(props) => <SlackAuth {...props} {...this.props} />}
            />
            <Route
              path="/lhv/:uuid_to_check"
              render={(props) => <LHViewer {...props} {...this.props} />}
            />
            <Route path="/post/">
              {" "}
              <CreateItem />
            </Route>
            <Route path="/">
              {" "}
              <ListItems />{" "}
            </Route>
          </Switch>
        </Layout>
      </Router>
    );
  }
}

export default Home;
