import { put, takeLatest } from "redux-saga/effects";
import API from "./../../ApiLayer/index.js";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
//
export function* verifyNotified(action) {
  yield put({ type: "VERIFYING_NOTIFIED" });
  try {
    const result = yield API.verifyNotified(action.payload);
    yield put({ type: "VERIFY_NOTIFIED_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "VERIFY_NOTIFIED_FAILED", payload: e });
  }
}

export function* inviteNotified(action) {
  yield put({ type: "INVITING_NOTIFIED" });
  try {
    const result = yield API.inviteNotified(action.payload);
    yield put({ type: "INVITE_NOTIFIED_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "INVITE_NOTIFIED_FAILED", payload: e });
  }
}

export function* detailNotified(action) {
  yield put({ type: "DETAILING_NOTIFIED" });
  try {
    const result = yield API.detailNotified(action.payload);
    yield put({ type: "DETAIL_NOTIFIED_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "DETAIL_NOTIFIED_FAILED", payload: e });
  }
}

export function* getNotified() {
  yield put({ type: "GETTING_NOTIFIED" });
  try {
    const result = yield API.getNotified();
    yield put({ type: "GET_NOTIFIED_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "GET_NOTIFIED_FAILED", payload: e });
  }
}

export function* deleteNotified(action) {
  yield put({ type: "DELETING_NOTIFIED" });
  try {
    const result = yield API.deleteNotified(action.payload);
    yield put({ type: "GET_WATCHER" });
    yield put({ type: "POST_NOTIFIED_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "POST_NOTIFIED_FAILED", payload: e });
  }
}

export function* postNotified(action) {
  yield put({ type: "POSTING_NOTIFIED" });
  try {
    const result = yield API.postNotified(action.payload);
    yield put({ type: "POST_NOTIFIED_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "POST_NOTIFIED_FAILED", payload: e });
  }
}

export function* getSlackFromCode(action) {
  try {
    const result = yield API.getSlackFromCode(action.payload);
    yield put({ type: "POST_SLACK_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "POST_SLACK_FAILED", payload: e });
  }
}
