import React, { useState } from "react";
import WidgetCTA from "./WidgetCTA.js";
import { Spin, Icon } from "antd";
import { ResponsiveLine } from "@nivo/line";
import { useTranslation } from "react-i18next";

const WidgetCurveGraph = (props) => {
  const [stateChecked, changeChecked] = useState(0);
  const { t } = useTranslation();
  let array_start = props.mobile ? 13 : 0;
  const data = [
    {
      id: "data",
      color: "hsl(178, 70%, 50%)",
      data: [...props.data.slice(array_start)],
    },
  ];
  let arrayY = Array.from(
    JSON.parse(JSON.stringify(props.data)).map((item) => item.y)
  );
  // really a hack for now
  let lowest_data = Math.min.apply(Math, arrayY);
  let highest_data = Math.max.apply(Math, arrayY);
  if (lowest_data == highest_data) {
    console.log("hello")
    lowest_data = 0
  }
  return (
    <div className="item item-curveGraph">
      <WidgetCTA />
      {!props.dragEnabled ? (
        <div className="item-content">
          <div className="item-content-header">
            {t(props.dataSet)} <small>{t("timeInSecond")}</small>
          </div>
          <div className="item-content-desc">{t(`${props.dataSet}-desc`)}</div>
          <div className="item-content-graph">
            {" "}
            <ResponsiveLine
              data={data}
              margin={{ top: 10, right: 20, bottom: 30, left: 50 }}
              yScale={{
                type: "linear",
                min: lowest_data,
                max: "auto",
                stacked: true,
                reverse: false,
              }}
              yFormat=" >-.2f"
              curve="monotoneX"
              axisTop={null}
              axisRight={null}
              axisLeft={{
                orient: "left",
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: "middle",
                tickValues: 5,
              }}
              axisBottom={{
                tickSize: 0,
                tickPadding: 5,
                tickValues: 5,
                tickRotation: -45,
                legendOffset: -40,
                legendPosition: "middle",
                format: function (value) {
                  const options = {
                    day: "numeric",
                    month: "numeric",
                  };
                  return new Date(value * 1000).toLocaleDateString(
                    undefined,
                    options
                  );
                },
              }}
              colors={"#1890FF"}
              lineWidth={1}
              pointColor={{ theme: "background" }}
              pointBorderWidth={1}
              pointBorderColor={{ from: "serieColor" }}
              pointLabelYOffset={-12}
              enableArea={true}
              enableGridX={false}
              enableCrosshair={false}
              areaBlendMode="multiply"
              areaBaselineValue={lowest_data}
              areaOpacity={0.1}
              useMesh={true}
              tooltip={(value) => {
                if (value.point.data.dummy === true) {
                  return "-";
                }
                let ts = value.point.data.x;
                const options = {
                  weekday: "short",
                  month: "short",
                  day: "numeric",
                  hour12: false,
                };
                let date = new Date(ts * 1000).toLocaleDateString(
                  undefined,
                  options
                );
                let time = value.point.data.y.toFixed(2);
                return `${date} - ${time} Seconds`;
              }}
            />
          </div>
        </div>
      ) : (
        <div className="item-content drag-me">
          <div className="item-content-header">{t(props.dataSet)}</div>
          <div className="item-content-desc">{t(`${props.dataSet}-desc`)}</div>
          <div className="item-content-graph drag-me">
            {t("drag-me")} <Icon type="drag" />
          </div>
        </div>
      )}
    </div>
  );
};

export default WidgetCurveGraph;
