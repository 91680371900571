export const homeout = {
  en: {
    "ho-welcome": "Welcome To",
    "ho-lg": "Continuer en Français",
    "ho-gs": "Log-In / Sign-Up",
    "ho-or": "Or",
    "ho-see": "Watch How We Can Help",
    "ho-title-1": "Tired of debugging in a hurry ?",
    "ho-desc-1":
      "Vautours alerts you every time something is wrong with your website. You get a Mail, a slack message or even an SMS*",
    "ho-title-2": "Want To Track Your SEO ?",
    "ho-desc-2":
      "Get A Ranking Of Different Parameters Impacting Your Search Engine Score. Get Info About Your Accessibility, Performances And Even Specific Tips About SEO",
  },
  fr: {
    "ho-welcome": "Bienvenue Sur",
    "ho-lg": "Switch to English",
    "ho-gs": "Commencer / S'inscrire",
    "ho-or": "Ou",
    "ho-see": "Apprenez-en plus",
    "ho-title-1": "Fatigué de déboguer en urgence ?",
    "ho-desc-1":
      "Vautours.net vous avertit chaque fois qu'un problème survient sur votre site web. Vous recevez un mail, un message slack ou même un SMS*",
    "ho-title-2": "Vous voulez suivre votre SEO ?",
    "ho-desc-2":
      "Obtenez votre score SEO, mais aussi des informations sur la Performance, l'Accessibilité et même des conseils spécifiques sur le référencement",
  },
};
