import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import API from "./../ApiLayer/index.js";
import { withTranslation } from "react-i18next";
import {
  Select,
  notification,
  Typography,
  Divider,
  Popover,
  Badge,
  Button,
  Layout,
  Icon,
  Form,
  Spin,
} from "antd";

import ComponentWatcher from "./../containers/ComponentWatcher.js";
import ComponentRessource from "./../containers/ComponentRessource.js";
import SeoDataCard from "./../containers/SeoDataCard.js";
import AskModal from "./../containers/AskModal.js";
const MaxNotifiedBasic = process.env.REACT_APP_MAXNOTIFIEDBASIC;
const MaxNotifiedPro = process.env.REACT_APP_MAXNOTIFIEDPRO;

const { Sider } = Layout;
const { Title } = Typography;
const { Option } = Select;

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.props.reset_checker();
    this.props.reset_watcher();
    let uuid_to_check = this.props.match.params.uuid_to_check;
    this.props.detail_checker(uuid_to_check);
    this.props.get_watcher();
    this.state = {
      selected_watcher: {},
      arr_signed_url: [],
      uuid_to_check: uuid_to_check,
      name_to_check: "",
      // TODO:  <03-08-20 modal should be abstracted> //
      showSlackModal: false,
      okSlackType: "primary",
      okSlackText: t("list-modal-healthy-ok"),
      titleModal: t("list-modal-delete-title"),
      contentSlackModal: t("list-modal-healthy-content"),
      // TODO: <10-05-21 should abstract once out of mvp
      showDeleteModal: false,
      okDeleteType: "danger",
      cancelDeleteText: t("list-modal-delete-cancel"),
      okDeleteText: t("list-modal-delete-ok"),
      titleModal: t("list-modal-delete-title"),
      contentDeleteModal: t("list-modal-delete-content"),
      temp_verification_method : this.props.current_checker.verification_method,
    };
  }

  // >>handling the delete should be facto
  onDelete() {
    this.setState({
      showDeleteModal: true,
    });
  }

  handleCancel = () => {
    console.log("Here");
    this.setState({
      showSlackModal: false,
      showHealthyModal: false,
      showDeleteModal: false,
    });
  };

  handleOkDelete = () => {
    this.setState({
      showDeleteModal: false,
    });
    this.props.delete_checker(this.props.match.params.uuid_to_check);
    this.props.get_checker();
    setTimeout(
      function () {
        window.location.href = "/";
      }.bind(this),
      1000
    );
  };
  // << handling the delete should be facto
  handleWatcherSelection(key) {
    try {
      key.target.getAttribute("datakey");
      this.props.reset_watcher();
      this.setState({
        new_watcher: true,
        selected_value: "Adding a watcher",
        selected_watcher: {
          name_to_watch: "",
          key_to_notify: [],
          array_to_notify: [],
        },
      });
    } catch (err) {
      err = "watcher not known yet";
      let selected_watcher = this.props.watchers.find(
        (watcher) => watcher.uuid_to_watch === key
      );
      this.setState({
        new_watcher: false,
        selected_value: key,
        selected_watcher: selected_watcher,
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    let uuid_to_check = this.props.current_checker.uuid_to_check;
    let uuid_to_watch = // either the current watcher or the selected
      this.state.selected_watcher.uuid_to_watch ||
      this.props.current_watcher.uuid_to_watch;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return err;
      values["uuid_to_check"] = uuid_to_check;
      values["uuid_to_watch"] = uuid_to_watch;
      // if uuid_to_watch is undefined the new
      if (uuid_to_watch === undefined) {
        // don't pass by redux sent watcher the sent checker
        async function post_watcher_update_checker(values, _) {
          let res = await _.props.post_watcher(values);
          if (res.error_message) {
            // if request unsuccessful
            notification.error({
              message: res.error_message,
              duration: 2,
              description: "Watcher's info missing",
            });
          } else {
            // if request successful
            let watcher = res.watcher;
            values = Object.assign({}, values, {
              ...watcher,
            });
            _.props.update_checker(values);
          }
        }
        post_watcher_update_checker(values, this);
      } else {
        this.props.update_checker(values);
        this.props.update_watcher(values);
      }
    });
    this.props.detail_checker(this.state.uuid_to_check);
  };

  get_signed_url = async (uuid_to_s, r_for) => {
    console.log(uuid_to_s, r_for);
    let arr = this.state.arr_signed_url;
    arr.push(uuid_to_s);
    this.setState({
      arr_signed_url: arr,
    });
    let oarr = arr.filter((x) => x !== uuid_to_s);
    try {
      const signedUrl = await API.getSignedUrl(uuid_to_s, r_for);
      window.open(signedUrl.url, "_blank");
    } catch (err) {
      //console.log(err);
    }
    this.setState({
      arr_signed_url: oarr,
    });
  };

  // TODO:  <03-08-20 modal should be abstracted> //
  // modal should be abstracted
  handleOk = () => {
    this.setState({
      showSlackModal: false,
    });
    this.props.detail_watcher(this.state.uuid_to_watch);
  };

  showSlackModal = (bool) => {
    this.setState({
      showSlackModal: true,
    });
  };

  changeVerificationMethod = (m) => {
    console.log(m)
    this.setState({
      temp_verification_method: m,
    });
  };


  render() {
    const { t } = this.props;
    const formItemLayout = {
      labelAlign: "left",
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const switchItemLayout = {
      labelAlign: "left",
      labelCol: {
        xs: { span: 24 },
        sm: { span: 15 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 6 },
      },
    };
    const maxNotified = () => {
      if (
        this.props.user.user_plan === undefined ||
        this.props.user.user_plan === "b"
      ) {
        return MaxNotifiedBasic;
      }
      return MaxNotifiedPro;
    };
    return (
      <Layout className="UpdateCheckItem">
        <AskModal
          cancelText={this.state.cancelDeleteText}
          okText={this.state.okDeleteText}
          okType={this.state.okDeleteType}
          showModal={this.state.showDeleteModal}
          titleModal={this.state.titleModal}
          contentModal={this.state.contentDeleteModal}
          handleOk={() => this.handleOkDelete()}
          handleCancel={() => this.handleCancel()}
        />
        <AskModal
          cancelText={this.state.cancelSlackText}
          okText={this.state.okSlackText}
          okType={this.state.okSlackType}
          showModal={this.state.showSlackModal}
          titleModal={this.state.titleModal}
          contentModal={this.state.contentSlackModal}
          handleCancel={() => this.handleCancel()}
          handleOk={() => this.handleOk()}
        />
        <div className="UpdateCheckItem-header">
          <div className="UpdateCheckItem-header-left">
            <div className="UpdateCheckItem-title">
              {this.props.current_checker.name_to_check}
            </div>
            <div className="UpdateCheckItem-route">
              {this.props.current_checker.route_to_check}
            </div>
          </div>
          <div className="UpdateCheckItem-header-right">
            <Link to={`/detail/${this.props.current_checker.uuid_to_check}`}>
              <Button className="btn">
                <Icon type="dashboard" />
                Dashboard
              </Button>
            </Link>
            <Button
              className="btn"
              type="danger"
              onClick={() => this.onDelete()}>
              {t("delete")}
              <Icon
                style={{ fontSize: "16px" }}
                type="delete"
                theme="twoTone"
                twoToneColor="white"
              />
            </Button>
          </div>
        </div>
        <div className="UpdateCenterColumn">
          <Form
            {...formItemLayout}
            onSubmit={this.handleSubmit}
            className="UpdateCheckItemForm">
            <Typography>
              <Title style={{ marginBottom: 0 }} level={3}>
                Checker
              </Title>
              {t("add-ressource")}
              <Divider />
            </Typography>
            <ComponentRessource
              {...this.props}
              changeVerificationMethod={this.changeVerificationMethod}
              temp_verification_method={this.state.temp_verification_method}
              switchItemLayout={switchItemLayout}
            />{" "}
            <br />
            <Typography>
              <Title level={3}>
                Watcher
                <Select
                  value={this.state.selected_value}
                  onChange={(key) => this.handleWatcherSelection(key)}
                  style={{ width: 240 }}
                  className="select-watcher"
                  placeholder={`${this.props.current_watcher.name_to_watch}`}
                  initialValue={`${this.props.current_watcher.name_to_watch}`}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: "4px 0" }} />
                      <div
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={(e) => {
                          this.handleWatcherSelection(e);
                        }}
                        datakey="AddAWatcher"
                        style={{ padding: "8px 8px", cursor: "pointer" }}>
                        <Icon type="plus" /> Add a watcher
                      </div>
                    </div>
                  )}>
                  {this.props.watchers.map((watch) => (
                    <Option key={watch.uuid_to_watch}>
                      {watch.name_to_watch}
                    </Option>
                  ))}
                </Select>
              </Title>
              {t("add-watcher")}
              <Divider />
            </Typography>
            <ComponentWatcher
              {...this.props}
              maxNotified={maxNotified()}
              showSlackModal={this.showSlackModal}
              current_watcher={
                this.state.selected_watcher.uuid_to_watch
                  ? this.state.selected_watcher
                  : this.props.current_watcher
              }
            />
            <Form.Item>
              <Button
                type="primary"
                icon="upload"
                size="large"
                className="checker-form-button"
                loading={this.props.loading}
                htmlType="submit">
                Update
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Layout>
    );
  }
}

const WIndex = Form.create({ name: "register" })(Index);

function mapDispatchToProps(dispatch) {
  return {
    get_checker: () => dispatch({ type: "GET_CHECKER" }),
    get_watcher: () => dispatch({ type: "GET_WATCHER" }),
    post_watcher: (data) => API.postWatch(data),
    delete_checker: (uuid) =>
      dispatch({ type: "DELETE_CHECKER", payload: uuid }),
    //getting info
    detail_checker: (data) =>
      dispatch({ type: "DETAIL_CHECKER", payload: data }),
    detail_watcher: (data) =>
      dispatch({ type: "DETAIL_WATCHER", payload: data }),
    //update info
    update_checker: (data) =>
      dispatch({ type: "UPDATE_CHECKER", payload: data }),
    update_watcher: (data) =>
      dispatch({ type: "UPDATE_WATCHER", payload: data }),
    // setting the loader for slack button
    asking_slack: () => dispatch({ type: "ASKING_SLACK" }),
    //reset info
    reset_checker: (data) => dispatch({ type: "RESET_CHECKER" }),
    reset_watcher: (data) => dispatch({ type: "RESET_WATCHER" }),
  };
}

function mapStateToProps(state) {
  return {
    form_reset: state.res_req.form_reset,
    loading: state.res_req.loading,
    messageSuccess: state.res_req.messageSuccess,
    messageError: state.res_req.messageError,
    current_checker: state.checker.current_checker,
    current_watcher: state.watcher.current_watcher,
    watchers: state.watcher.watchers,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WIndex));
