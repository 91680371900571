import React, { Component } from "react";
import Auth from "./../auth0";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import UserInfo from "./../containers/UserInfo.js";
import { Tooltip, Typography, Table, Badge, Tag, Icon, Layout } from "antd";

const expandedRowRender = (watcher, props) => {
  const { t } = props;
  const columns = [
    {
      title: t("settings-ressource-notified-name-label"),
      key: "key_to_notify",
      render: noti => {
        return !noti.key_to_notify.includes("https://hooks.slack.com")
          ? noti.key_to_notify
          : `Slack - ${noti.slack_channel_name}`;
      }
    },
    {
      title: (
        <span>
          {t("settings-ressource-notified-auth-label")}
          <Tooltip title={t("settings-ressource-notified-auth-tooltip")}>
            {" "}
            <Icon type="question-circle-o" />
          </Tooltip>
        </span>
      ),
      dataIndex: "auth_to_notify",
      width: "120px",
      key: "auth_to_notify",
      render: bool =>
        bool ? (
          <Tag color="green">{t("settings-ressource-notified-auth")}</Tag>
        ) : (
          <Tag color="red">{t("settings-ressource-notified-unauth")}</Tag>
        )
    },
    {
      title: t("settings-ressource-notified-action-label"),
      key: "action",
      render: noti => (
        <div>
          <a onClick={() => props.deleteNoti(noti)}>
            <Icon
              style={{ fontSize: "16px" }}
              type="delete"
              theme="twoTone"
              twoToneColor="red"
            />{" "}
            {t("settings-ressource-delete")}
          </a>
          {!noti.auth_to_notify && (
            <>
              {" "}
              /{" "}
              <a onClick={() => props.inviteNotified(noti.uuid_to_notify)}>
                <Icon
                  style={{ fontSize: "16px" }}
                  type="mail"
                  theme="twoTone"
                />{" "}
                {t("settings-ressource-invite")}
              </a>
            </>
          )}
        </div>
      )
    }
  ];
  return (
    <Table
      tableLayout="fixed"
      fixed
      rowKey="uuid_to_notify"
      columns={columns}
      dataSource={watcher.array_to_notify}
      pagination={false}
    />
  );
};

export default expandedRowRender;
