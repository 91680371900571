import React, { Component } from "react";
import { Divider, Icon, Tooltip } from "antd";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import { withTranslation } from "react-i18next";

//const { TextArea } = Input;

class WidgetsComponent extends Component {
  render() {
    const { t } = this.props;
    const today = new Date();
    const values = [];
    const lgCode = this.props.i18n.language;
    let array_failures = [];
    this.props.checkers
      .filter(x => x.array_failures !== undefined)
      .filter(x => x.array_failures.length > 0)
      .map(x => array_failures.push(...x.array_failures.slice(0)));
    if (array_failures.length > 1) {
      array_failures
        .filter(f => f.failure_status !== "Decoy")
        .map(f => {
          const options = {
            weekday: "long",
            month: "long",
            day: "numeric"
          };
          const options_long = {
            ...options,
            hour: "numeric",
            minute: "numeric",
            hour12: false
          };
          let date = new Date(f.failure_time * 1000);
          values.push({
            date_h: date.toLocaleDateString(lgCode, options),
            date_l: date.toLocaleDateString(lgCode, options_long),
            uuid_to_fail: f.uuid_to_fail,
            failure_status: f.failure_status,
            failure_file_url: f.failure_file_url,
            date: date,
            count: 1
          });
        });
    }
    return (
      <div className="WidgetsComponent">
        <div className="illustrationSvg-box">
          <div className="box-layout" />
          <div className="greetings">
            <div className="greetings-title">
              {this.props.some_in_trouble
                ? t("greetings-sider-notok-title")
                : t("greetings-sider-ok-title")}{" "}
              {` ${this.props.user.nickname}.`}
            </div>
            <div className="greetings-subtitle">
              {!this.props.some_in_trouble
                ? `${t("greetings-sider-ok-subtitle")}`
                : this.props.in_trouble === 1
                ? `${t("greetings-sider-notok-subtitle")}`
                : `${this.props.in_trouble}/${this.props.checkers.length} ${t(
                    "greetings-sider-notok-subtitle-plus"
                  )}`}
            </div>
          </div>
          {this.props.some_in_trouble ? (
            <img src="/pblmSvg.svg" alt="pblmSvg" className="illustrationSvg" />
          ) : (
            <img
              src="/allGoodSvg.svg"
              alt="allGoodSvg"
              className="illustrationSvg"
            />
          )}
        </div>

        {!(values === 0) && (
          <div className="DetailDataCal">
            <CalendarHeatmap
              classForValue={value => {
                if (!value) {
                  return "color-empty";
                }
                return `color-scale`;
              }}
              startDate={new Date(today.setMonth(today.getMonth() - 6))}
              endDate={this.today}
              transformDayElement={(rect, value, index) => {
                if (value != null) {
                  return (
                    <Tooltip
                      key={index}
                      placement="left"
                      title={`${t("detail-historic-file-tt-first")} - ${
                        value.date_h
                      }`}>
                      {rect}
                    </Tooltip>
                  );
                } else {
                  return (
                    <Tooltip
                      key={index}
                      placement="left"
                      title={t("detail-historic-cal-nofail")}>
                      {rect}
                    </Tooltip>
                  );
                }
              }}
              values={values}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(WidgetsComponent);
