import { put, takeLatest } from "redux-saga/effects";
import API from "./../../ApiLayer/index.js";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
//
export function* getWatch() {
  yield put({ type: "GETTING_WATCHER" });
  try {
    const Watchs = yield API.getWatch();
    yield put({ type: "GET_WATCHER_SUCCESS", payload: Watchs });
  } catch (e) {
    yield put({ type: "GET_WATCHER_FAILED", payload: e });
  }
}

export function* postWatch(action) {
  yield put({ type: "POSTING_WATCHER" });
  try {
    const result = yield API.postWatch(action.payload);
    let uuid_to_watch = result["uuid_to_watch"]
    // make sure the uuid is gotten then the request is sent 
    return uuid_to_watch
  } catch (e) {
    yield put({ type: "POST_WATCHER_FAILED", payload: e });
  }
}

export function* deleteWatch(action) {
  yield put({ type: "DELETING_WATCHER" });
  try {
    const result = yield API.deleteWatch(action.payload);
    console.log(result)
    if (result.status_code !== 200) {
      throw result
    }
    yield put({ type: "GET_WATCHER" });
    yield put({ type: "DELETE_WATCHER_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "DELETE_WATCHER_FAILED", payload: e });
  }
}

export function* detailWatch(action) {
  yield put({ type: "DETAILING_WATCHER" });
  try {
    const result = yield API.detailWatch(action.payload);
    yield put({ type: "DETAIL_WATCHER_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "DETAIL_WATCHER_FAILED", payload: e });
  }
}

export function* updateWatch(action) {
  yield put({ type: "UPDATING_WATCHER" });
  try {
    const result = yield API.updateWatch(action.payload);
    yield put({ type: "UPDATE_WATCHER_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "UPDATE_WATCHER_FAILED", payload: e });
  }
}
