import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Typography, Divider, Button, Layout, Form } from "antd";
import AskModal from "./../containers/AskModal.js";

import ComponentWatcher from "./../containers/ComponentWatcher.js";

const MaxNotifiedBasic = process.env.MaxNotifiedBasic;
const MaxNotifiedPro = process.env.MaxNotifiedPro;
const { Title } = Typography;

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.props.reset_watcher();
    let uuid_to_watch = this.props.match.params.uuid_to_watch;
    this.props.detail_watcher(uuid_to_watch);
    this.state = {
      uuid_to_watch: uuid_to_watch,
      // TODO:  <03-08-20 modal should be abstracted> //
      showSlackModal: false,
      okSlackType: "primary",
      okSlackText: t("list-modal-healthy-ok"),
      titleModal: t("list-modal-delete-title"),
      contentSlackModal: t("list-modal-healthy-content")
    };
  }

  maxNotified = () => {
    if ( this.props.user.user_plan === undefined || this.props.user.user_plan === "b" ) {
      return MaxNotifiedBasic;
    }
    return MaxNotifiedPro;
  };

  handleSubmit = e => {
    e.preventDefault();
    let uuid_to_watch = this.props.match.params.uuid_to_watch;
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (err) return err;
      values["uuid_to_watch"] = uuid_to_watch;
      this.props.update_watcher(values);
    });
  };

  handleChange = value => {};

  // TODO:  <03-08-20 modal should be abstracted> //
  handleOk = () => {
    this.setState({
      showSlackModal: false
    });
    this.props.detail_watcher(this.state.uuid_to_watch);
  };

  handleCancel = () => {
    this.setState({
      showSlackModal: false
    });
  };

  showSlackModal = bool => {
    this.setState({
      showSlackModal: true
    });
  };

  render() {
    const formItemLayout = {
      labelAlign: "left",
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    };
    return (
      <Layout className="DetailWatcher">
        <AskModal
          cancelText={this.state.cancelSlackText}
          okText={this.state.okSlackText}
          okType={this.state.okSlackType}
          showModal={this.state.showSlackModal}
          titleModal={this.state.titleModal}
          contentModal={this.state.contentSlackModal}
          handleCancel={() => this.handleCancel()}
          handleOk={() => this.handleOk()}
        />

        <Form
          onChange={this.handleChange}
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="CreateCheckItemForm">
          <Typography>
            <Title level={3}>Watcher</Title>
            <Divider />
          </Typography>
          <ComponentWatcher
            {...this.props}
            maxNotified={this.maxNotified}
            showSlackModal={this.showSlackModal}
            current_watcher={this.props.current_watcher}
          />
          <Form.Item>
            <Button
              type="primary"
              icon="upload"
              size="large"
              className="checker-form-button"
              loading={this.props.loading}
              htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Layout>
    );
  }
}

const WIndex = Form.create({ name: "register" })(Index);

function mapDispatchToProps(dispatch) {
  return {
    get_watcher: () => dispatch({ type: "GET_WATCHER" }),
    //getting info
    detail_watcher: data => dispatch({ type: "DETAIL_WATCHER", payload: data }),
    //update info
    update_watcher: data => dispatch({ type: "UPDATE_WATCHER", payload: data }),
    //reset info
    reset_watcher: data => dispatch({ type: "RESET_WATCHER" }),
    // setting the loader
    asking_slack: () => dispatch({ type: "ASKING_SLACK" })
  };
}

function mapStateToProps(state) {
  return {
    form_reset: state.res_req.form_reset,
    loading: state.res_req.loading,
    messageSuccess: state.res_req.messageSuccess,
    messageError: state.res_req.messageError,
    current_watcher: state.watcher.current_watcher,
    watchers: state.watcher.watchers,
    user : state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(WIndex));
