import { put } from "redux-saga/effects";
import API from "./../../ApiLayer/index.js";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
export function* getCheck() {
  yield put({ type: "GETTING_CHECKER" });
  try {
    const checks = yield API.getCheck();
    yield put({ type: "GET_CHECKER_SUCCESS", payload: checks });
  } catch (e) {
    yield put({ type: "GET_CHECKER_FAILED", payload: e });
  }
}

export function* postCheck(action) {
  yield put({ type: "POSTING_CHECKER" });
  try {
    const result = yield API.postCheck(action.payload);
    yield put({ type: "POST_CHECKER_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "POST_CHECKER_FAILED", payload: e });
  }
}

export function* deepDetailCheck(action) {
  yield put({ type: "DETAILING_CHECKER" });
  try {
    const result = yield API.deepDetailCheck(action.payload);
    yield put({ type: "DETAIL_CHECKER_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "DETAIL_CHECKER_FAILED", payload: e });
  }
}

export function* detailCheck(action) {
  yield put({ type: "DETAILING_CHECKER" });
  try {
    const result = yield API.detailCheck(action.payload);
    if (result.uuid_to_watch) {
      yield put({ type: "DETAIL_WATCHER", payload: result.uuid_to_watch });
    } else {
      yield put({ type: "RESET_WATCHER" });
    }
    yield put({ type: "DETAIL_CHECKER_SUCCESS", payload: result });
  } catch (e) {
    console.log(e)
    yield put({ type: "DETAIL_CHECKER_FAILED", payload: e });
  }
}

export function* updateCheck(action) {
  yield put({ type: "UPDATING_CHECKER" });
  try {
    const result = yield API.updateCheck(action.payload);
    yield put({ type: "UPDATE_CHECKER_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "UPDATE_CHECKER_FAILED", payload: e });
  }
}

export function* deleteCheck(action) {
  yield put({ type: "DELETING_CHECKER" });
  try {
    const result = yield API.deleteCheck(action.payload);
    if (result.error_message) {
      throw result.error_message;
    }
    yield put({ type: "DELETE_CHECKER_SUCCESS", payload: result });
    yield put({ type: "GET_CHECKER" });
  } catch (e) {
    yield put({ type: "DELETE_CHECKER_FAILED", payload: e });
  }
}

export function* healthyCheck(action) {
  yield put({ type: "HEALTHYING_CHECKER" });
  try {
    const result = yield API.updateCheck(action.payload);
    yield put({ type: "UPDATE_CHECKER_SUCCESS", payload: result });
  } catch (e) {
    yield put({ type: "UPDATE_CHECKER_FAILED", payload: e });
  }
}
