import React, { Component } from "react";
import { connect } from "react-redux";
import NotifiedCodeForm from "./../containers/notifiedCodeForm";
import { Typography, Layout, Icon, Form, Input, Button } from "antd";

const { Title, Paragraph, Text } = Typography;

class Index extends Component {
  constructor(props) {
    super(props);
  }

  handleSubmit() {
    let token = this.props.match.params.token;
    this.props.verify_notified(token);
  }

  render() {
    return (
      <Layout id="DetailNotified">
        <Typography>
          <Title level={2}>Welcome to vautours.net</Title>
          <Title>Validate your e-mail to get notified</Title>
          <Paragraph>
            <br />
            <strong />
          </Paragraph>
          <Paragraph>
            <h3> Welcome to Vautours.net,</h3>
            You have received a request to be added to a Watcher list.
            <br />
            Click here to give your agreement and receive emails. <br />
            You will not receive any e-mails promotional offers from us.
            <br />
            <strong>
              If you want to know more{" "}
              <a href="https://vautours.net">click here</a>{" "}
            </strong>
          </Paragraph>
          <Paragraph>
            <h3> Bienvenue sur vautour,</h3> vous avez reçu une demande d’ajout
            à une liste de Watcher.
            <br /> Cliquez ici pour donner votre accord et recevoir les mails.
            <br />
            <strong>
              vous ne recevrez en aucun cas des mails promotionnels de notre
              part. si vous voulez en savoir plus{" "}
              <a href="https://vautours.net">cliquer ici</a>{" "}
            </strong>
          </Paragraph>
          <Button
            type="primary"
            onClick={() => this.handleSubmit()}
            icon="download"
            loading={this.props.loading}>
            {" "}
            Submit{" "}
          </Button>
        </Typography>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    detail_notified: data =>
      dispatch({ type: "DETAIL_NOTIFIED", payload: data }),
    verify_notified: data =>
      dispatch({ type: "VERIFY_NOTIFIED", payload: data })
  };
}

function mapStateToProps(state) {
  return {
    form_reset: state.res_req.form_reset,
    loading: state.res_req.loading,
    messageSuccess: state.res_req.messageSuccess,
    messageError: state.res_req.messageError,
    current_notified: state.notified.current_notified
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
