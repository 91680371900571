import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, Button, Typography } from "antd";

const { Paragraph, Title } = Typography;

const NewsCardDetail = (props) => {
  const { t } = useTranslation();
  //<div className="newsCardHeader">{props.newInfo.title}</div>
  //<div className="newsCardFooter"></div>
  return (
    <div className="newsContainer">
      <div className="newsCardDetail">
        <Title className="newsCardTitle" level={3}>
          {props.newInfo.title}
        </Title>
        <Paragraph
          className="newsCardBody"
          ellipsis={{ rows: 3, expandable: true }}>
          {props.newInfo.body}
        </Paragraph>
        <Button size="large" type="default">
          Click Here to see more
        </Button>
      </div>
    </div>
  );
};

export default NewsCardDetail;
