import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Icon, Result, Button, notification, Modal } from "antd";
import i18n from "./../my-i18next.js";

class N {
  constructor() {
    this.worthy_codes = [
      "f_notified_fin_b_001",
      "f_notified_fin_p_001",
      "f_notified_fin_e_001",
      "f_notified_fin_001",
      "f_checker_fin_b_001",
      "f_checker_fin_p_001",
      "f_checker_fin_e_001",
      "f_wat_update_001",
      "f_wat_created_001",
      "f_checker_create_001",
      "f_checker_get_001",
      "f_checker_update_001",
      "f_checker_delete_001",
      "f_verify_missing_kw_001",
      "f_verify_unreachable_route_001",
      "f_verify_invalid_url_001",
      "f_verify_timeout_001",
      "f_auth_token_expired",
      "f_auth_claims_error",
      "f_auth_exception",
      "f_user_not_found",
      "f_user_layout",
      "f_checker_ssl_001",

      "s_checker_delete_001",
      "s_checker_create_001",
      "s_checker_update_001",
      "s_notified_create_001",
      "s_watcher_update_001",
      "s_user_layout",
      "s_checker_ssl_001",
    ];
  }
  evaluate(json) {
    if (json.success_code !== undefined) {
      this.notify_success(json);
    }
    if (json.error_code !== undefined) {
      this.notify_error(json);
    }
    return;
  }

  notify_success(json) {
    let success_code = json.success_code;
    if (!this.worthy_codes.includes(success_code)) {
      return;
    }
    if (success_code === "s_checker_create_001") {
    } else {
      notification.success({
        message: "Success",
        duration: 2,
        description: i18n.t(json.success_code),
      });
    }
  }

  notify_error(json) {
    let error_code = json.error_code;
    function Welcome(message) {
      return <span dangerouslySetInnerHTML={{ __html: message }} />;
    }
    if (!this.worthy_codes.includes(error_code)) {
      return;
    }
    notification.error({
      message: "Error",
      duration: 0,
      description: Welcome(i18n.t(json.error_code)),
    });
  }
}

const Notification = new N();
export default Notification;
