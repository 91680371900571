import React from "react";

export default class AdComponent extends React.Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    return (
      <div className='adSense'>
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-format="fluid"
        data-ad-layout-key="-eh+5w+4e-db+86"
        data-ad-client="ca-pub-1597486652294367"
        data-ad-slot="9124263474"
      />
      </div>
    );
  }
}

