import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import {  Switch, Tooltip, Icon } from "antd";

import ASeoBar from "./ASeoBar.js";

class SeoDataCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { t } = this.props;
    const get_date_from_ts = (ts) => {
      if (ts == "N/A") {
        return "?";
      }
      var date = new Date(ts * 1000);
      const options = {
        //weekday: "short",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        //hour12: false
      };
      return date.toLocaleDateString(undefined, options);
    };

    const last_report_time_formated = () => {
      if (
        this.props.current_checker.seo_report === undefined ||
        this.props.current_checker.seo_report < 1
      ) {
        return false;
      }
      let af = this.props.current_checker.seo_report;
      af.sort((a, b) => {
        return parseFloat(b.seo_report_time) - parseFloat(a.seo_report_time);
      });
      var unix_timestamp = af[0].report_time;
      let date = get_date_from_ts(unix_timestamp);
      return date;
    };

    let all_reports = this.props.current_checker.seo_report.slice(0, 26);
    [...Array(26 - all_reports.length).keys()].map((i) => {
      all_reports.unshift({
        performance_score: "N/A",
        best_practices_score: "N/A",
        seo_score: "N/A",
        accessibility_score: "N/A",
        report_time: "N/A",
      });
    });
    let get_key = (r) => {
      let rt = r["report_time"];
      if (rt === "N/A") {
        rt = Math.random() * 10000;
      }
      return `${this.props.label}_score_${rt}`;
    };

    const get_class = (score) => {
      let className;
      if (score > 0.66) {
        className = "high";
      }
      if (0.66 >= score > 0.33) {
        className = "midd";
      }
      if (score <= 0.33) {
        className = "low";
      }
      if (score === "-1") {
        className = "null";
      }
      if (score === "N/A") {
        className = "never";
      }
      return className;
    };

    // gettign the score
    let this_score = () => {
      let relevant_score = this.props.current_checker.seo_report.filter(
        (i) => i[`${this.props.label}_score`] !== "-1"
      );
      if (relevant_score.length == 0) {
        return <div className="score score-null">N/A</div>;
      }

      relevant_score.sort((a, b) => {
        return parseFloat(b.seo_report_time) - parseFloat(a.seo_report_time);
      });

      let cscore = relevant_score[0][`${this.props.label}_score`];
      let score = (cscore * 100).toFixed(1);
      return <div className={`score score-${get_class(cscore)}`}>{score}%</div>;
    };

    // avg to have the overall score
    let get_avg = () => {
      let sum = 0;
      let relevant_score = this.props.current_checker.seo_report.filter(
        (i) => i[`${this.props.label}_score`] !== "-1"
      );

      relevant_score.map(
        (s) => (sum += parseFloat(s[`${this.props.label}_score`]))
      );
      if (relevant_score.length === 0) {
        return (
          <div className={"avg avg-null"}>
            {t("unknown")} <Icon type="file-unknown-o" />
          </div>
        );
      }

      let score = sum / relevant_score.length;
      let avg = (score * 100).toFixed(1);
      return (
        <div
          className={`avg avg-${get_class(score)}`}
          icon={<Icon type="logout" />}>
          {t("average")} {avg}%
        </div>
      );
    };

    return (
        <div className="SeoDataCard">
          {get_avg()}
          <div className="top-left">
            <div className="label-name">{t(this.props.label)}</div>
            <div className="last-report">{last_report_time_formated()}</div>
          </div>
          <div className="top-right">{this_score()}</div>
          <div className="historic">
            <div className="bars">
              {all_reports.map((r) => (
                <ASeoBar
                  label={this.props.label}
                  get_class={get_class}
                  key={get_key(r)}
                  get_date_from_ts={get_date_from_ts}
                  r={r}
                />
              ))}
            </div>
          </div>
        </div>
    );
  }
}

export default withTranslation()(SeoDataCard);
