const notified_is = {
  current_notified: {},
  notifieds: [],
};

const notified_reducer = (state = notified_is, action) => {
  switch (action.type) {
    case 'RESET_NOTIFIED':
      let newrstate = Object.assign({}, state, {
        current_notified: {
        },
      });
      return newrstate;

    case 'UPDATE_NOTIFIED_SUCCESS':
      let unotified = [...action.payload];
      let newustate = Object.assign({}, state, {
        notifieds: [...unotified],
      });
      return newustate;

    case 'GET_NOTIFIED_SUCCESS':
      let gnotified = [...action.payload];
      let newgstate = Object.assign({}, state, {
        notifieds: [...gnotified],
      });
      return newgstate;

    case 'DETAIL_NOTIFIED_SUCCESS':
      let newdstate = Object.assign({}, state, {
        current_notified: {
          ...action.payload,
        },
      });
      return newdstate;

    default:
      return state;
  }
};

export default notified_reducer;
