import React from "react";

export class FormStepSuccess extends React.Component {
  render() {
    return (
      <div className="post-checker-success-step">
        <img
          className="post-checker-success-img"
          src="/post-checker-success-img.png"
          alt=""
        />
        <div className="post-checker-success-title">Great Job</div>
        <div className="post-checker-success-sub-title">You Just Created a watcher go see it</div>
      </div>
    );
  }
}
