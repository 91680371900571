import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tooltip, Badge, Icon } from "antd";

class ASeoBar extends Component {
  // not a switch cause https://stackoverflow.com/questions/6665997/switch-statement-for-greater-than-less-than
  render() {
    let r = this.props.r;
    let height = r[`${this.props.label}_score`];
    let barClass = this.props.get_class(height);
    return (
      <Tooltip
        placement="left"
        title={`${this.props.get_date_from_ts(r["report_time"])} - ${
          height === "-1" || height === "N/A" ? "N/A" : parseFloat(height * 100).toFixed(2) + "%"
        }`}>
        <div className="bar-holder">
          <div
            className={`bar bar-${barClass}`}
            style={{ height: `${height !== "-1" ? height * 100 : 100}%` }}
          />
        </div>
      </Tooltip>
    );
  }
}

export default ASeoBar;
