import React, { Component } from "react";
import ReportViewer from "react-lighthouse-viewer";
import { Spin } from "antd";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.props.reset_checker();
    let uuid_to_check = this.props.match.params.uuid_to_check;
    this.props.detail_checker(uuid_to_check);
  }

  render() {
    const { t } = this.props;
    return (
      <div className="LHViewer">
        {this.props.current_checker.seo_report === undefined ||
        this.props.current_checker.seo_report.length === 0 ? (
          <Spin spinning />
        ) : (
          <ReportViewer
            json={JSON.parse(
              this.props.current_checker.seo_report[0].report_json
            )}
          />
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    detail_checker: (data) =>
      dispatch({ type: "DETAIL_CHECKER", payload: data }),
    reset_checker: (data) => dispatch({ type: "RESET_CHECKER" }),
  };
}

function mapStateToProps(state) {
  return {
    current_checker: state.checker.current_checker,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Index));
