import React, { Component } from "react";
import { Icon, Divider, Select, Form, Input } from "antd";
import { withTranslation } from "react-i18next";

const { TextArea } = Input;
const redirect_url = process.env.REACT_APP_AUTH0_CALLBACK_URL;
const slack_client_id = process.env.REACT_APP_SLACK_CLIENT_ID;
const { Option } = Select;

export class FormStepWatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxN: this.props.MaxNotified || 5,
      selected_watcher: false,
    };
    this.props.get_watcher();
  }
  handleWatcherSelection(key) {
    if (key === undefined) {
      this.setState({
        selected_watcher: false,
      });
    } else {
      let selected_watcher = this.props.watchers.find(
        (watcher) => watcher.uuid_to_watch === key
      );
      this.setState({
        new_watcher: false,
        selected_value: key,
        selected_watcher: selected_watcher,
      });
    }
  }
  render() {
    const { t } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="fragment">
        {this.props.watchers.length !== 0 && (
          <>
            <Form.Item>
              {getFieldDecorator("uuid_to_watch", {
                rules: [],
              })(
                <Select
                  style={{}}
                  onChange={(key) => this.handleWatcherSelection(key)}
                  className="select-watcher"
                  allowClear
                  clearIcon={
                    <Icon
                      type="delete"
                      theme="twoTone"
                      style={{ fontSize: "16px" }}
                      twoToneColor="#eb2f96"
                    />
                  }
                  placeholder={`Select One Of Your Watcher`}
                  initialValue={`${this.state.selected_watcher.value}`}
                  dropdownRender={(menu) => <div>{menu}</div>}>
                  {this.props.watchers.map((watch) => (
                    <Option key={watch.uuid_to_watch}>
                      {watch.name_to_watch}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </>
        )}
        {!this.state.selected_watcher && (
          <>
            {this.props.watchers.length !== 0 && (
              <Divider>Or Create A New One</Divider>
            )}
            <Form.Item
              label={
                <>
                  <span>{t("watcher-name-label")}</span>{" "}
                  <small>/ {t("watcher-name-tooltip")}</small>
                </>
              }>
              {getFieldDecorator("name_to_watch", {
                initialValue: `${this.props.data.name_to_watch}`,
                rules: [
                  {
                    required: !this.state.selected_watcher,
                    message: t("watcher-name-error"),
                    whitespace: false,
                  },
                ],
              })(<Input placeholder="eg : Watchlist DevOps" />)}
            </Form.Item>
            <Form.Item
              label={
                <>
                  <span>{t("watcher-notified-label")}</span>{" "}
                  <small>/ {t("watcher-notified-tooltip")}</small>
                </>
              }>
              {getFieldDecorator("key_to_notify", {
                initialValue: this.props.data.array_to_notify.map(
                  (item) => item.key_to_notify
                ),
                rules: [
                  {
                    type: "array",
                    max: this.state.maxN,
                    message: t("watcher-notified-too-much-error", {
                      maxNotified: this.state.maxN,
                    }),
                  },
                  {
                    type: "pattern",
                    required: this.state.selected_watcher,
                    message: t("watcher-notified-error"),
                  },
                ],
              })(
                <Select
                  mode="tags"
                  tokenSeparators={[","]}
                  style={{ width: "100%" }}
                  placeholder="eg : admin@website.com">
                  {this.props.data.array_to_notify
                    //.filter(item => item.key_to_notify.includes("@"))
                    .map((item) =>
                      item.key_to_notify.includes("https://hooks.slack.com") ? (
                        <Option
                          value={item.key_to_notify}
                          key={item.key_to_notify}>
                          <Icon type="slack" /> {item.slack_channel_name}
                        </Option>
                      ) : (
                        <Option
                          value={item.key_to_notify}
                          key={item.key_to_notify}>
                          {item.key_to_notify}
                        </Option>
                      )
                    )}
                </Select>
              )}
            </Form.Item>

            <Form.Item label={<span>{t("watcher-message-label")}</span>}>
              {getFieldDecorator("textalert_to_watch", {
                initialValue: `${this.props.data.textalert_to_watch}`,
                rules: [
                  {
                    type: "pattern",
                    required: this.state.selected_watcher,
                    message: t("watcher-message-error"),
                  },
                ],
              })(
                <TextArea rows={4} placeholder={`eg : The website is down`} />
              )}
            </Form.Item>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(FormStepWatcher);

//const redirect_uri = // if redirect_uri === false I know watcher not yet created
//this.props.data.uuid_to_watch === undefined
//? false
//: redirect_url + "/slack/" + this.props.data.uuid_to_watch;
//<Form.Item
//label={
//<>
//<span>{t("watcher-slack-label")}</span>
//</>
//}>
//<Button
//onClick={() => {
//this.props.asking_slack();
//window.open(
//`https://slack.com/oauth/v2/authorize?redirect_uri=${redirect_uri}&scope=incoming-webhook&client_id=${slack_client_id}`
//);
//this.props.showSlackModal();
//}}
//disabled={!redirect_uri}
//icon="slack"
//type="primary"
//loading={this.props.loading ? true : false}
//shape="round"
//size="default">
//{t("watcher-slack-add-to")}
//</Button>
//</Form.Item>
