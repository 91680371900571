import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Switch, Badge, Icon, Tooltip } from "antd";

class CardItem extends Component {
  render() {
    const { t } = this.props;
    const onDevChange = (checked, e) => {
      let uuid_to_check = this.props.uuid_to_check;
      this.props.onDevChange(uuid_to_check, checked);
    };

    const last_formated_failure = () => {
      if (
        this.props.array_failures == undefined ||
        this.props.array_failures.length < 1
      ) {
        return false;
      }
      let af = this.props.array_failures.slice(0);
      af.sort((a, b) => {
        return parseFloat(b.failure_time) - parseFloat(a.failure_time);
      });
      var unix_timestamp = af[0].failure_time;
      var date = new Date(unix_timestamp * 1000);
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      };
      return date.toLocaleDateString(undefined, options);
    };
    return (
      <div className="card-box">
        <div className="card">
          {this.props.verification_method === "dont" ? (
            <Badge
              className={`bounce-false badge-status`}
              count={
                <Icon
                  style={{ fontSize: "16px" }}
                  type="minus-circle"
                  theme="twoTone"
                  twoToneColor="#A9A9A9"
                />
              }
            />
          ) : (
            <>
              {this.props.status_to_check ? (
                <Badge
                  className={`bounce-false badge-status`}
                  count={
                    <Icon
                      style={{ fontSize: "16px" }}
                      type="check-circle"
                      theme="twoTone"
                      twoToneColor="#52c41a"
                    />
                  }
                />
              ) : (
                <Badge
                  className={`bounce-true badge-status`}
                  count={
                    <Icon
                      style={{ fontSize: "16px" }}
                      type="exclamation-circle"
                      theme="twoTone"
                      twoToneColor="red"
                    />
                  }
                />
              )}
            </>
          )}
          {this.props.daysRemaining &&
            this.props.route_to_check.includes("https://") && (
              <Tooltip
                placement="top"
                title={
                  this.props.ssl_valid
                    ? `${t("ssl-tooltip")} ${this.props.daysRemaining} ${t(
                        "ssl-day-long"
                      )}`
                    : t("ssl-tooltip-not-valid")
                }>
                <Badge
                  style={{
                    backgroundColor: `${
                      !this.props.ssl_valid
                        ? "volcano"
                        : this.props.daysRemaining < 30
                        ? "orange"
                        : "lightGreen"
                    }`,
                  }}
                  className={`badge-ssl`}
                  count={`SSL : ${this.props.daysRemaining}${t(
                    "ssl-day-short"
                  )}`}
                />
              </Tooltip>
            )}
          <div className="card-header">
            {!this.props.status_to_check && (
              <span
                className="badge-container"
                onClick={(e) => {
                  e.preventDefault();
                  this.props.onHealthy(this.props.uuid_to_check);
                }}>
                <Badge
                  count={
                    <Icon
                      title="Is the issue close"
                      className="to-point"
                      type="safety-certificate"
                      theme="twoTone"
                    />
                  }
                />
              </span>
            )}
            {this.props.seo_should_to_check && (
              <Link to={`/detail/${this.props.uuid_to_check}#SEO`}>
                <div className="badge-container">
                  <Badge
                    count={
                      <Icon title="SEO" type="fund" theme="twoTone" />
                    }
                  />
                </div>
              </Link>
            )}
            <Link to={`/detail/${this.props.uuid_to_check}`}>
              <div className="badge-container">
                <Badge
                  count={
                    <Icon title="Dashboard" type="dashboard" theme="twoTone" />
                  }
                />
              </div>
            </Link>
            <Link to={`/update/${this.props.uuid_to_check}`}>
              <div className="badge-container">
                <Badge
                  count={
                    <Icon title="Settings" type="setting" theme="twoTone" />
                  }
                />
              </div>
            </Link>
            <div
              onClick={(e) => {
                e.preventDefault();
                this.props.onDelete(this.props.uuid_to_check);
              }}
              className="badge-container">
              <Badge
                count={
                  <Icon
                    title="Delete"
                    className="to-point"
                    type="delete"
                    theme="twoTone"
                  />
                }
              />
            </div>
          </div>
          <div className="card-last-update">
            {this.props.last_failure_text} :{" "}
            {last_formated_failure() || this.props.no_failure_text}
          </div>
          <Link to={`/detail/${this.props.uuid_to_check}`}>
            <div className="card-body">
              <div className="card-body-website-name">
                {this.props.name_to_check}
              </div>
              <div className="card-body-website-route">
                {this.props.route_to_check}
              </div>
              <div className="card-body-info">
                <span
                  onClick={(e) => e.preventDefault()}
                  className="card-dev-mode-box">
                  Dev Mode :{" "}
                  <Switch
                    size="small"
                    valuepropname="checked"
                    defaultChecked={this.props.dev_mode_to_check}
                    onChange={onDevChange}
                    loading={this.props.loading}
                  />{" "}
                </span>
              </div>
            </div>
          </Link>
          <Link to={`/watcher/${this.props.uuid_to_watch}`}>
            <div className="card-body-watch-group">
              {this.props.watcher
                ? this.props.watcher.name_to_watch
                : "Unknown watcher"}
              <Icon type="bell" />
            </div>
          </Link>
        </div>
      </div>
    );
  }
}

export default CardItem;
