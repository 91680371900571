import React, { Component } from "react";
import { Typography, Spin, Icon, Tooltip } from "antd";
import CalendarHeatmap from "react-calendar-heatmap";
import DetailDataFiles from "./MonitoringTab/DetailDataFiles.js";
import SSLMonitoring from "./MonitoringTab/SSLMonitoring.js";
import "react-calendar-heatmap/dist/styles.css";
import { withTranslation } from "react-i18next";

//const { TextArea } = Input;

class DetailDataComp extends Component {
  render() {
    const { t } = this.props;
    const today = new Date();
    const { Title } = Typography;
    const values = [];
    const lgCode = this.props.i18n.language;
    if (this.props.current_checker.array_failures) {
      const value_mapper = this.props.current_checker.array_failures
        .filter((x) => x.failure_status != "Decoy") // getting rid of all the decoy
        .map((f) => {
          const options = {
            weekday: "long",
            month: "long",
            day: "numeric",
          };
          const options_long = {
            ...options,
            hour: "numeric",
            minute: "numeric",
            hour12: false,
          };
          let date = new Date(f.failure_time * 1000);
          values.push({
            date_h: date.toLocaleDateString(lgCode, options),
            date_l: date.toLocaleDateString(lgCode, options_long),
            uuid_to_fail: f.uuid_to_fail,
            failure_status: f.failure_status,
            failure_file_url: f.failure_file_url,
            failure_time: f.failure_time,
            key: f.uuid_to_fail,
            date: date,
            count: 1,
          });
        });
    }
    return (
      <div className="DetailDataComp">
        {this.props.current_checker.ssl_should_to_check && (
          <SSLMonitoring si={this.props.current_checker.ssl_information} uuid_to_check={this.props.current_checker.uuid_to_check} />
        )}
        {!(values === 0) && (
          <div className="DetailDataCal">
            <CalendarHeatmap
              className="CalendarHeatmap"
              startDate={new Date(today.setMonth(today.getMonth() - 6))}
              endDate={this.today}
              transformDayElement={(rect, value, index) => {
                if (value != null) {
                  return (
                    <Tooltip
                      key={index}
                      placement="top"
                      title={`${t("detail-historic-file-tt-first")} - ${
                        value.date_h
                      }`}>
                      {rect}
                    </Tooltip>
                  );
                } else {
                  return (
                    <Tooltip
                      key={index}
                      placement="top"
                      title={t("detail-historic-cal-nofail")}>
                      {rect}
                    </Tooltip>
                  );
                }
              }}
              values={values}
            />
          </div>
        )}
        <DetailDataFiles values={values} {...this.props} />
      </div>
    );
  }
}

export default withTranslation()(DetailDataComp);
