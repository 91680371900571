import {combineReducers} from 'redux';
import res_req_reducer from './req_res-reducer';
import checker_reducer from './checker-reducer';
import watcher_reducer from './watcher-reducer';
import notified_reducer from './notified-reducer';
import user_reducer from './user-reducer';

const reducer = combineReducers({
  res_req: res_req_reducer,
  checker: checker_reducer,
  watcher: watcher_reducer,
  notified: notified_reducer,
  user: user_reducer,
});
export default reducer;
