import React, { Component } from "react";
import { Icon, Tooltip, Button, Select, Form, Input } from "antd";
import { withTranslation } from "react-i18next";

const { TextArea } = Input;
const redirect_url = process.env.REACT_APP_AUTH0_CALLBACK_URL;
const slack_client_id = process.env.REACT_APP_SLACK_CLIENT_ID;
const { Option } = Select;

class componentWatcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maxN: this.props.MaxNotified || 5,
    };
  }
  render() {
    const redirect_uri = // if redirect_uri === false I know watcher not yet created
      this.props.current_watcher.uuid_to_watch === undefined
        ? false
        : redirect_url + "/slack/" + this.props.current_watcher.uuid_to_watch;
    const { t } = this.props;
    const children = [];
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="fragment">
        <Form.Item label={<span>{t("watcher-name-label")}</span>}>
          {getFieldDecorator("name_to_watch", {
            initialValue: `${this.props.current_watcher.name_to_watch}`,
            rules: [
              {
                required: true,
                message: t("watcher-name-error"),
                whitespace: false,
              },
            ],
          })(<Input placeholder="eg : Watchlist DevOps" />)}
        </Form.Item>
        <Form.Item label={t("watcher-notified-label")}>
          {getFieldDecorator("key_to_notify", {
            initialValue: this.props.current_watcher.array_to_notify.map(
              (item) => item.key_to_notify
            ),
            rules: [
              {
                type: "array",
                max: this.state.maxN,
                message: t("watcher-notified-too-much-error", {
                  maxNotified: this.state.maxN,
                }),
              },
              {
                type: "pattern",
                required: true,
                message: t("watcher-notified-error"),
              },
            ],
          })(
            <Select
              mode="tags"
              tokenSeparators={[","]}
              style={{ width: "100%" }}
              placeholder="eg : admin@website.com">
              {this.props.current_watcher.array_to_notify
                //.filter(item => item.key_to_notify.includes("@"))
                .map((item) =>
                  item.key_to_notify.includes("https://hooks.slack.com") ? (
                    <Option value={item.key_to_notify} key={item.key_to_notify}>
                      <Icon type="slack" /> {item.slack_channel_name}
                    </Option>
                  ) : (
                    <Option value={item.key_to_notify} key={item.key_to_notify}>
                      {item.key_to_notify}
                    </Option>
                  )
                )}
            </Select>
          )}
        </Form.Item>

        <Form.Item
          label={
            <span>
              {t("watcher-slack-label")}
              {redirect_uri ? (
                <Tooltip title={t("checker-route-tooltip")}>
                  {" "}
                  <Icon type="question-circle-o" />{" "}
                </Tooltip>
              ) : (
                ""
              )}
            </span>
          }>
          <Button
            onClick={() => {
              this.props.asking_slack();
              window.open(
                `https://slack.com/oauth/v2/authorize?redirect_uri=${redirect_uri}&scope=incoming-webhook&client_id=${slack_client_id}`
              );
              this.props.showSlackModal();
            }}
            disabled={!redirect_uri}
            icon="slack"
            type="primary"
            loading={this.props.loading ? true : false}
            shape="round"
            size="default">
            {t("watcher-slack-add-to")}
          </Button>
        </Form.Item>
        <Form.Item label={t("watcher-message-label")}>
          {getFieldDecorator("textalert_to_watch", {
            initialValue: `${this.props.current_watcher.textalert_to_watch}`,
            rules: [
              {
                type: "pattern",
                required: true,
                message: t("watcher-message-error"),
              },
            ],
          })(<TextArea rows={4} placeholder={`eg : The website is down`} />)}
        </Form.Item>
      </div>
    );
  }
}

export default withTranslation()(componentWatcher);
