import React, {Component} from 'react';
import {Typography, Layout, Icon, Form, Input, Button} from 'antd';

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class HorizontalLoginForm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.handleSubmit()
  };
  render() {
    const {getFieldDecorator} = this.props.form;

    // Only show error after a field is touched.
    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        You have been asked to submit a code do it ?
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.props.loading}>
            Send
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
const WrappedHorizontalLoginForm = Form.create({
  name: 'horizontal_login',
})(HorizontalLoginForm);

export default WrappedHorizontalLoginForm;
