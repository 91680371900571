import React, { useState } from "react";
import Reward from "react-rewards";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tag, Icon, Switch } from "antd";

const CustomSwitchFormItem = React.forwardRef((props) => {
  const { onChange, checked } = props;
  const user_plan = props.user_plan;
  const is_pro = props.is_pro;
  const [stateChecked, changeChecked] = useState(checked);
  let reward = React.useRef("reward");

  const triggerChange = () => {
    if (onChange) {
      if (user_plan === "b" && is_pro) {
        reward.punishMe(); //weird method name but hey
        return;
      }
      changeChecked(!stateChecked);
      onChange(!stateChecked);
    }
  };
  const { t } = useTranslation();
  return (
    <Reward
      key={props.key}
      ref={(ref) => {
        reward = ref;
      }}
      type="emoji">
      <div
        onClick={() => triggerChange()}
        className={`CustomSwitchFormItem ${
          stateChecked ? "checked" : "unchecked"
        }`}>
        {props.is_pro && (
          <Tag color="blue">
            <Icon type="star" /> PRO
          </Tag>
        )}
        <div className="CustomSwitchFormItem-title">
          {t(`${props.feature}-title`)}
        </div>
        <div className="CustomSwitchFormItem-desc">
          {t(`${props.feature}-desc`)}{" "}
        </div>
        <Switch size="small" checked={stateChecked} />
      </div>
    </Reward>
  );
});

export default CustomSwitchFormItem;
