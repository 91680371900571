import React, { Component } from "react";
import Auth from "./../auth0";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import UserInfo from "./../containers/UserInfo.js";
import NotifiedRow from "./../containers/NotifiedRow.js";
import AskModal from "./../containers/AskModal.js";
import { Tooltip, Typography, Table, Badge, Tag, Icon, Layout } from "antd";

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.props.get_watcher();
    this.props.get_notified();
    this.state = {
      showHealthyModal: false,
      showDeleteModal: false,
      okHealthyType: "danger",
      okDeleteType: "danger",
      cancelDeleteText: t("list-modal-delete-cancel"),
      okDeleteText: t("list-modal-delete-ok"),
      okHealthyText: t("list-modal-healthy-ok"),
      contentHealthyModal: t("list-modal-healthy-content"),
      titleModal: t("list-modal-delete-title"),
      contentDeleteModal: t("list-modal-delete-content")
    };
  }
  onHealthy(uuid_to_check) {
    this.setState({
      showHealthyModal: true,
      uuid_to_check: uuid_to_check
    });
  }
  onDelete(uuid_to_check) {
    this.setState({
      showDeleteModal: true,
      uuid_to_check: uuid_to_check
    });
  }
  handleCancel = () => {
    this.setState({
      showHealthyModal: false,
      showDeleteModal: false
    });
  };

  handleOkHealthy = uuid => {
    this.setState({
      showHealthyModal: false
    });
    this.props.healthy_checker(uuid);
  };
  handleOkDelete = uuid => {
    this.setState({
      showDeleteModal: false
    });
    this.props.delete_checker(uuid);
  };

  deleteNotified = noti => {
    // TODO:  <24-07-20, modal to see if delete> //
    this.props.delete_notified(noti.uuid_to_notify);
  };

  render() {
    const { t } = this.props;
    const columns = [
      {
        title: t("settings-ressource-watcher-name-label"),
        dataIndex: "name_to_watch",
        key: "name_to_watch"
      },
      {
        title: t("settings-ressource-watcher-status-label"),
        dataIndex: "array_to_notify",
        key: "array_to_notify",
        render: array =>
          array.some(noti => !noti.auth_to_notify) ? (
            <Badge
              status="error"
              text={t("settings-ressource-watcher-status-failure")}
            />
          ) : (
            <Badge
              status="success"
              text={t("settings-ressource-watcher-status-healthy")}
            />
          )
      },
      {
        title: t("settings-ressource-notified-action-label"),
        key: "action",
        render: watcher => (
          <div>
            <a onClick={() => this.props.delete_watcher(watcher.uuid_to_watch)}>
              <Icon
                style={{ fontSize: "16px" }}
                type="delete"
                theme="twoTone"
                twoToneColor="red"
              />{" "}
              {t("settings-ressource-delete")}
            </a>
          </div>
        )
      }
    ];

    const { Title } = Typography;
    return (
      <Layout className="ListRessource">
        <AskModal
          cancelText={this.state.cancelDeleteText}
          okText={this.state.okHealthyText}
          okType={this.state.okHealthyType}
          showModal={this.state.showHealthyModal}
          titleModal={this.state.titleModal}
          contentModal={this.state.contentHealthyModal}
          uuid={this.state.uuid_to_check}
          handleOk={uuid => this.handleOkHealthy(uuid)}
          handleCancel={() => this.handleCancel()}
        />
        <AskModal
          cancelText={this.state.cancelDeleteText}
          okText={this.state.okDeleteText}
          okType={this.state.okDeleteType}
          showModal={this.state.showDeleteModal}
          titleModal={this.state.titleModal}
          contentModal={this.state.contentDeleteModal}
          uuid={this.state.uuid_to_check}
          handleOk={uuid => this.handleOkDelete(uuid)}
          handleCancel={() => this.handleCancel()}
        />
        <Typography>
          {" "}
          <Title level={3}>{t("settings-user-title")}</Title>{" "}
        </Typography>
        <UserInfo user={this.props.user} />
        <Typography>
          {" "}
          <Title level={3}>{t("settings-ressource-title")}</Title>{" "}
        </Typography>
        <Table
          pagination={false}
          className="watcher-table"
          scroll={{ x: 500 }}
          rowKey="uuid_to_watch"
          columns={columns}
          dataSource={this.props.watchers}
          expandedRowRender={watcher =>
            NotifiedRow(watcher, {
              ...this.props,
              deleteNoti: this.deleteNotified,
              inviteNotified: this.props.invite_notified
            })
          }
          rowExpandable={true}
          defaultExpandAllRows={true}
          expandIconColumnIndex={0}
        />
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    post_checker: data => dispatch({ type: "POST_CHECKER", payload: data }),
    get_watcher: () => dispatch({ type: "GET_WATCHER" }),
    delete_watcher: (uuid_to_watch) => dispatch(
      { type: "DELETE_WATCHER", payload : uuid_to_watch }
    ),
    get_notified: () => dispatch({ type: "GET_NOTIFIED" }),
    invite_notified: (uuid_to_notify) => dispatch({ type: "INVITE_NOTIFIED", payload : uuid_to_notify }),
    ask_notified: uuid_to_notify =>
      dispatch({ type: "ASK_NOTIFIED", payload: uuid_to_notify }),
    delete_notified: uuid_to_notify =>
      dispatch({ type: "DELETE_NOTIFIED", payload: uuid_to_notify })
  };
}

function mapStateToProps(state) {
  return {
    form_reset: state.res_req.form_reset,
    loading: state.res_req.loading,
    messageSuccess: state.res_req.messageSuccess,
    messageError: state.res_req.messageError,
    watchers: state.watcher.watchers,
    notifieds: state.notified.notifieds,
    user: state.user
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Index));
