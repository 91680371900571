//  [partApp] - [Success/Failure] - [id] - [Action?]
export const statusCode = {
  en: {
    "Slack-F-001-AddCodeTitle": "Failure to add slack to the connection.",
    "Slack-F-002-AddCodeDesc": "There was a connection problem. Try again soon",
    s_notified_create_001: "The new notified has been successfully created",
    f_notified_fin_b_001:
      "You are not allowed to link more notified in your plan. See the <a href='https://vautours.net/#pricing'> pricing page</a> for more info.",
    f_notified_fin_p_001:
      "You are not allowed to link more notified in your plan. See the <a href='https://vautours.net/#pricing'> pricing page</a> for more info.",
    f_notified_fin_e_001:
      "You are not allowed to link more notified in your plan. See the <a href='https://vautours.net/#pricing'> pricing page</a> for more info.",
    f_notified_fin_001:
      "You are not allowed to link more notified in your plan. See the <a href='https://vautours.net/#pricing'> pricing page</a> for more info.",
    f_checker_fin_b_001:
      "You are not allowed to create more checkers in your plan. See the <a href='https://vautours.net/#pricing'> pricing page</a> for more info.",
    f_checker_fin_p_001:
      "You are not allowed to create more checkers in your plan. See the <a href='https://vautours.net/#pricing'> pricing page</a> for more info.",
    f_checker_fin_e_001:
      "You are not allowed to create more checkers in your plan. See the <a href='https://vautours.net/#pricing'> pricing page</a> for more info.",
    s_watcher_update_001: "the watcher has been updated.",
    s_watcher_created_001: "the watcher has been created.",
    s_checker_create_001: "the checker has been created.",
    s_checker_get_001: "Successfully fetched the checkers.",
    s_checker_update_001: "The checker has been updated",
    s_checker_delete_001: "The checker has been deleted",
    f_watcher_update_001: "the watcher could not be updated",
    f_watcher_created_001: "the watcher could not be created",
    f_checker_token_001: "We could not reach that URL",
    f_checker_create_001: "the checker could not be created",
    f_checker_get_001: "the checkers could not be fetched.",
    f_checker_update_001: "the checker could not be updated",
    f_checker_delete_001: "the checker could not be deleted",
    f_checker_ssl_001: "We were unable to fetch the SSL information. Please refresh this page and retry.",
    f_verify_not_200_001: "We were able to contact the server but it did not return a 200 status code.",
    f_verify_missing_kw_001:
      "The webpage was successfully loaded but we could not find any of your keywords on it. Find more information about this error <a href='https://vautours.net/keywords-on-page'> here</a>.",
    f_token_unreachable_route_001:
      "We were unable to reach the route you wanted to check.",
    f_token_invalid_url_001: "The URL you wanted to check is not valid.",
    f_token_timeout_001:
      "We could not reach the URL you wanted us to check in a timely manner.",
    f_verify_unreachable_route_001:
      "We were unable to reach the route you wanted to check. Find more information <a href='https://vautours.net/unreachable-route'> here</a>.",
    f_verify_invalid_url_001:
      "The URL you wanted to check is not valid. Find more information <a href='https://vautours.net/invalid-url'> here</a>.",
    f_verify_timeout_001:
      "We could not reach the URL you wanted us to check in a timely manner. See more about TimeOut errors<a href='https://vautours.net/timeouterror'> here</a>.",
    f_auth_token_expired:
      "Your authentification token has expired. Please disconnect/reconnect yourself.",
    f_auth_claims_error:
      "Your authentification token was missing claims. Please contact and with the code f_auth_claims_error",
    f_auth_exception:
      "We had a problem logging you in. Please contact and with the code f_auth_exception",
    f_user_not_found:
      "We had a problem logging you in. Please contact and with the code f_auth_user_not_found",
    f_user_layout: "We had trouble updating this layout",
    s_user_fetched: "successfully fetched the user",
    s_user_layout: "You successfully updated this layout",
    s_checker_ssl_001: "We are fetching the ssl informations. Refresh this page in a minute please.",
  },
  fr: {
    "Slack-F-001-AddCodeTitle": "Problème de connection avec Slack",
    "Slack-F-002-AddCodeDesc":
      "Nous avons rencontré un problème avec l'intégration de slack",

    s_notified_create_001: "Le nouveau notifié a bien été ajouté.",
    f_notified_fin_b_001:
      "Vous ne pouvez pas lier plus de notifiés à un watcher sous votre pricing. Voir la page<a href='https://vautours.net/#pricing'>pricing </a> pour plus d'informations.",
    f_notified_fin_p_001:
      "Vous ne pouvez pas lier plus de notifiés à un watcher sous votre pricing. Voir la page<a href='https://vautours.net/#pricing'>pricing </a> pour plus d'informations.",
    f_notified_fin_e_001:
      "Vous ne pouvez pas lier plus de notifiés à un watcher sous votre pricing. Voir la page<a href='https://vautours.net/#pricing'>pricing </a> pour plus d'informations.",
    f_notified_fin_001:
      "Vous ne pouvez pas lier plus de notifiés à un watcher sous votre pricing. Voir la page<a href='https://vautours.net/#pricing'>pricing </a> pour plus d'informations.",
    f_checker_fin_b_001:
      "Vous n'êtes pas autorisé à créer plus de Checkers dans votre plan. Voir la page<a href='https://vautours.net/#pricing'>pricing </a> pour plus d'informations.",
    f_checker_fin_p_001:
      "Vous n'êtes pas autorisé à créer plus de Checkers dans votre plan. Voir la page<a href='https://vautours.net/#pricing'>pricing </a> pour plus d'informations.",
    f_checker_fin_e_001:
      "Vous n'êtes pas autorisé à créer plus de Checkers dans votre plan. Voir la page<a href='https://vautours.net/#pricing'>pricing </a> pour plus d'informations.",
    s_wat_update_001: "le Watcher a été mis à jour.",
    s_wat_created_001: "le Watcher a été créé.",
    s_checker_create_001: "le Checker a été créé.",
    s_checker_get_001: "Nous avons bien mis à jour vos Checkers.",
    s_checker_update_001: "Le Checker a été mis à jour",
    s_checker_delete_001: "Le Checker a été supprimé",
    f_wat_update_001: "le Watcher ne pouvait pas être mis à jour",
    f_wat_created_001: "le Watcher n'a pas pu être créé",
    f_checker_token_001: "Nous n'arrivons pas à joindre cette URL",
    f_checker_create_001: "le Checker n'a pas pu être créé",
    f_checker_get_001:
      "Nous avons rencontré un problème durant la récupération de vos checkers.",
    f_checker_update_001: "le Checker n'a pas pu être mis à jour",
    f_checker_delete_001: "le Checker n'a pas pu être supprimé",
    f_checker_ssl_001: "Nous n'avons pas pu récupérer les informations SSL. Veuillez actualiser cette page et réessayer.",
    f_verify_not_200_001: "Nous avons réussi à joindre l'URL demandé, mais celle-ci ne nous a pas retourné un statut HTTP 200.",
    f_verify_missing_kw_001:
      "La page Web a été chargé avec succès, mais nous n'y avons pas trouvé vos mots-clés. Pour plus d'informations sur cette erreur, veuillez cliquer <a href='https://vautours.net/keywords-on-page'>ici</a>.",
    f_token_unreachable_route_001:
      "Nous n'avons pas réussi à joindre l'URL demandé.",
    f_token_invalid_url_001:
      "L'URL que vous vouliez vérifier n'est pas valide.",
    f_token_timeout_001:
      "Nous avons rencontré une erreur TimeOut à l'URL que vous vouliez vérifier.",
    f_verify_unreachable_route_001:
      "Nous n'avons pas réussi à joindre l'URL demandé. Pour plus d'informations sur cette erreur, veuillez cliquer a href='https://vautours.net/unreachable-route'>ici </a>.",
    f_verify_invalid_url_001:
      "L'URL que vous vouliez vérifier n'est pas valide. Pour plus d'informations sur cette erreur, veuillez cliquer <a href='https://vautours.net/invalid-url'> ici</a>.",
    f_verify_timeout_001:
      "Nous avons rencontré une erreur TimeOut à l'URL que vous vouliez vérifier. Pour plus d'informations sur cette erreur, veuillez cliquer <a href='https://vautours.net/timeouterror'>ici </a>.",
    f_auth_token_expired:
      "Votre token d'authentification a expiré. Veuillez, s'il vous plaît vous déconnecter / reconnecter.",
    f_auth_claims_error:
      "Nous avons eu un problème durant votre connexion. code f_auth_claims_error",
    f_auth_exception:
      "Nous avons eu un problème durant votre connexion. code f_auth_exception",
    f_user_not_found:
      "Nous avons eu un problème durant votre connexion. code  f_auth_user_not_found",
    f_user_layout: "Nous avons eu un problème durant la mise à jour du layout.",
    s_user_fetched: "Success dans le detail de votre utilisateur",
    s_user_layout: "Layout mis à jour avec succès",
    s_checker_ssl_001: "Nous récupérons les informations SSL. Rafraîchissez cette page dans une minute s'il vous plaît.",

  },
};
