import React, { Component } from "react";
import Reward from "react-rewards";
import API from "./../../ApiLayer/index.js";
//import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { message, Badge, Icon } from "antd";

class RequestSSL extends Component {
  constructor(props) {
    super(props);
    this.state = {spinning : false }
  }
  handleClick = async () => {
    this.setState({spinning : true })
    const { t } = this.props;
    let response = await API.requestSSLCheck(this.props.uuid_to_check);
    console.log(response)
    this.setState({spinning : false })
    // this.reward.punishMe();
  };

  render() {
    const { t } = this.props;
    return (
      <div className="request-ssl">
        <Reward
          ref={(ref) => {
            this.reward = ref;
          }}
          type="emoji"
        >
          <div className="" onClick={this.handleClick}>
            <span className="">{t("ssl_ask_request")}</span>
            <span className="">
              {" "}
              <Icon type="sync"  spin={this.state.spinning}/>
            </span>
          </div>
        </Reward>
      </div>
    );
  }
}

export default withTranslation()(RequestSSL);
