import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, Icon, Button } from "antd";
const { Title, Paragraph } = Typography;

const MarketPlace = (props) => {
  const { t } = useTranslation();
  const isInterested = localStorage.getItem("interested_in_market");
  const [isInterestedState, changeisInterestedState] = useState(false);
  return (
    <div className="marketplaceTabs" style={{ padding: "20px" , maxWidth:"900px"}}>
      <Title level={2}>{t("interested_in")}</Title>
      <Title level={4}>{t("desc_marketplace_1")}</Title>
      <Paragraph>{t("desc_marketplace_2")}
    </Paragraph>
      <Paragraph>
        {!isInterested && !isInterestedState ? (
          <Button
            className="btn"
            onClick={() => {
              changeisInterestedState(true);
              localStorage.setItem("interested_in_market", true);
            }}>
            {" "}
            <Icon type="heart" />
            {t("i_would_be_interested")}{" "}
          </Button>
        ) : (
          <Button className="btn" type="primary">
            {" "}
            <Icon type="star" />
            {t("i_am_interested")}{" "}
          </Button>
        )}
      </Paragraph>
      <small>{t("warning_vote")}</small>
    </div>
  );
};

export default MarketPlace;
