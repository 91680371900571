import React, { useState } from "react";
import Reward from "react-rewards";
import { Modal, Form, Result } from "antd";
import { withTranslation } from "react-i18next";
import { Button, Divider, Select, Icon, Steps, Tooltip } from "antd";
import { FormStepChecker } from "./FormStepChecker.js";
import { FormStepFeature } from "./FormStepFeature.js";
import { FormStepWatcher } from "./FormStepWatcher.js";
import { FormStepSuccess } from "./FormStepSuccess.js";

const { Step } = Steps;

const CreateCheckerModal = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    getOkText = (currentStep) => {
      if (currentStep < 2) {
        return "next-button";
      }
      if (currentStep === 2) {
        return "add-button";
      }
      if (currentStep === 3) {
        return "go-see-it";
      }
    };
    getCancelText = (currentStep) => {
      if (currentStep === 0) {
        return "cancel";
      }
      if (currentStep < 3) {
        return "previous";
      }
      if (currentStep === 3) {
        return "close";
      }
    };
    render() {
      const { t } = this.props;
      return (
        <div id="CreateCheckerModal">
          <Modal
            keyboard={false}
            maskClosable={false}
            wrapClassName="create-checker-wrap"
            okButtonProps={{ loading: this.props.loading }}
            visible={this.props.visible}
            title={
              this.props.checkers.length > 0
                ? t("create-checker")
                : t("card-not-yet-1")
            }
            cancelText={t(this.getCancelText(this.props.currentStep))}
            okText={
              <Reward
                ref={this.props.rewardRef}
                lifetime={40}
                startVelocity={10}
                type="confetti">
                {t(this.getOkText(this.props.currentStep))}
              </Reward>
            }
            onCancel={this.props.onCancel}
            onOk={this.props.onCreate}>
            {this.props.currentStep !== 3 && (
              <>
                <Steps
                  current={this.props.currentStep}
                  size="small"
                  labelPlacement="vertical">
                  <Step title="Checker" />
                  <Step title="Features" />
                  <Step title="Watcher" />
                </Steps>
                <Divider orientation="left">
                  <small>{t(this.props.getDividerText())}</small>
                </Divider>
                <Form layout="vertical">
                  {this.props.currentStep === 0 && (
                    <FormStepChecker
                      {...this.props}
                      data={this.props.data}
                      suffixRouteInput={this.props.suffixRouteInput}
                      infoAboutRoute={this.props.infoAboutRoute}
                      inputRouteChange={this.props.inputRouteChange}
                      children={this.props.children}
                      fetchingTokens={this.props.fetchingTokens}
                      changeVerificationMethod={this.props.changeVerificationMethod}
                    />
                  )}
                  {this.props.currentStep === 1 && (
                    <FormStepFeature data={this.props.data} {...this.props} />
                  )}
                  {this.props.currentStep === 2 && (
                    <FormStepWatcher
                      data={this.props.data}
                      maxNotified={this.props.maxNotified()}
                      current_watcher={this.props.current_watcher}
                      {...this.props}
                    />
                  )}
                </Form>
              </>
            )}
            {this.props.currentStep === 3 && (
              <FormStepSuccess
                new_checker_uuid={this.props.new_checker_uuid}
                {...this.props}
              />
            )}
          </Modal>
        </div>
      );
    }
  }
);

export default withTranslation()(CreateCheckerModal);
