import React, { Component } from "react";
import { Skeleton, Layout } from "antd";
import Auth from "./../auth0";
import { connect } from "react-redux";
import CardItem from "./../containers/CardItem.js";
import CreateItem from "./../pages/CreateItem.js";
import AskModal from "./../containers/AskModal.js";
import WidgetsComponent from "./../containers/WidgetsComponent.js";
import { withTranslation } from "react-i18next";
import API from "./../ApiLayer/index.js";

class index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    this.state = {
      arr_signed_url: [],
      showHealthyModal: false,
      showDeleteModal: false,
      okHealthyType: "danger",
      okDeleteType: "danger",
      last_failure_text: t("list-last-failure-text"),
      no_failure_text: t("list-no-failure-text"),
      cancelDeleteText: t("list-modal-delete-cancel"),
      okDeleteText: t("list-modal-delete-ok"),
      okHealthyText: t("list-modal-healthy-ok"),
      contentHealthyModal: t("list-modal-healthy-content"),
      titleModal: t("list-modal-delete-title"),
      contentDeleteModal: t("list-modal-delete-content"),
      uuid_to_check: "",
      loading: true,
      checkers: [
        {
          route_to_check: "192.168.86.56/ping:5000",
          uuid_to_check: "loading-uuid",
        },
      ],
      new_watcher: true,
      selected_value: t("watcher-add-watcher"),
      selected_watcher: {
        name_to_watch: "",
        array_to_notify: [],
      },
    };
  }

  componentWillMount() {
    //getting the data
    this.props.get_checker();
    this.props.get_watcher();
    //reseting for the detail view
    this.props.reset_checker();
    this.props.reset_watcher();
  }

  onHealthy(uuid_to_check) {
    this.setState({
      showHealthyModal: true,
      uuid_to_check: uuid_to_check,
    });
  }
  onDelete(uuid_to_check) {
    this.setState({
      showDeleteModal: true,
      uuid_to_check: uuid_to_check,
    });
  }
  handleCancel = () => {
    this.setState({
      showHealthyModal: false,
      showDeleteModal: false,
    });
  };

  handleOkHealthy = (uuid) => {
    this.setState({
      showHealthyModal: false,
    });
    this.props.healthy_checker(uuid);
  };
  handleOkDelete = (uuid) => {
    this.setState({
      showDeleteModal: false,
    });
    this.props.delete_checker(uuid);
  };

  handleDevModeChange = (uuid_to_check, bool_mode) => {
    let values = {
      uuid_to_check: uuid_to_check,
      dev_mode_to_check: bool_mode,
    };
    this.props.update_checker(values);
  };

  render() {
    return (
      <Layout id="list-items">
        <Layout id="main-list-items">
          <AskModal
            cancelText={this.state.cancelDeleteText}
            okText={this.state.okHealthyText}
            okType={this.state.okHealthyType}
            showModal={this.state.showHealthyModal}
            titleModal={this.state.titleModal}
            contentModal={this.state.contentHealthyModal}
            uuid={this.state.uuid_to_check}
            handleOk={(uuid) => this.handleOkHealthy(uuid)}
            handleCancel={() => this.handleCancel()}
          />
          <AskModal
            cancelText={this.state.cancelDeleteText}
            okText={this.state.okDeleteText}
            okType={this.state.okDeleteType}
            showModal={this.state.showDeleteModal}
            titleModal={this.state.titleModal}
            contentModal={this.state.contentDeleteModal}
            uuid={this.state.uuid_to_check}
            handleOk={(uuid) => this.handleOkDelete(uuid)}
            handleCancel={() => this.handleCancel()}
          />
          {this.props.checkers.length === 0 ? (
            <CreateItem />
          ) : this.props.checkers === undefined ||
            this.props.checkers[0].uuid_to_check === "loading-uuid" ? (
            <div className="card-grid">
              {" "}
              <Skeleton
                loading
                active
                avatar
                className="skeleton-loading-card"
              />
              <Skeleton
                loading
                active
                avatar
                className="skeleton-loading-card"
              />
              <Skeleton
                loading
                active
                avatar
                className="skeleton-loading-card"
              />
              <Skeleton
                loading
                active
                avatar
                className="skeleton-loading-card"
              />{" "}
            </div>
          ) : (
            <div className="card-grid">
              {
                //.filter(checker => checker.status_to_check)
                this.props.checkers.map((checker) => (
                  <CardItem
                    {...checker}
                    onDelete={(uuid_to_check) => this.onDelete(uuid_to_check)}
                    onHealthy={(uuid_to_check) => this.onHealthy(uuid_to_check)}
                    last_failure_text={this.state.last_failure_text}
                    no_failure_text={this.state.no_failure_text}
                    key={checker.uuid_to_check}
                    {...this.props}
                    watcher={this.props.watchers.find(
                      (watcher) =>
                        watcher.uuid_to_watch === checker.uuid_to_watch
                    )}
                    onDevChange={this.handleDevModeChange}
                  />
                ))
              }
            </div>
          )}
        </Layout>
        {this.props.checkers.length !== 0 && (
          <Layout id="sider-list-items">
            <WidgetsComponent
              {...this.props}
              arr_signed_url={this.state.arr_signed_url}
              some_in_trouble={
                this.props.checkers.filter((x) => !x.status_to_check).length !=
                0
              }
              in_trouble={
                this.props.checkers.filter((x) => !x.status_to_check).length
              }
            />
          </Layout>
        )}
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    get_checker: () => dispatch({ type: "GET_CHECKER" }),
    get_watcher: () => dispatch({ type: "GET_WATCHER" }),
    reset_checker: () => dispatch({ type: "RESET_CHECKER" }),
    reset_watcher: () => dispatch({ type: "RESET_WATCHER" }),
    healthy_checker: (uuid) =>
      dispatch({
        type: "HEALTHY_CHECKER",
        payload: { uuid_to_check: uuid, status_to_check: true },
      }),
    post_checker: (data) => dispatch({ type: "POST_CHECKER", payload: data }),
    update_checker: (data) =>
      dispatch({ type: "UPDATE_CHECKER", payload: data }),
    delete_checker: (uuid) =>
      dispatch({ type: "DELETE_CHECKER", payload: uuid }),
  };
}

function mapStateToProps(state) {
  return {
    watchers: state.watcher.watchers,
    checkers: state.checker.checkers,
    loading: state.res_req.loading,
    new_checker_uuid: state.res_req.new_checker_uuid,
    user: state.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(index));
