import React from "react";
import { Form, Input, Radio } from "antd";
import { Spin, Select, Icon, Steps, Tooltip } from "antd";

const { Option } = Select;

export class FormStepChecker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      possibleVerificationMethods: [
        { value: "200" },
        { value: "kw" },
        { value: "dont" },
      ],
    };
  }

  render() {
    const { t } = this.props;
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <>
        <Form.Item
          className="step-1"
          label={
            <>
              <span>{t("checker-name-label")} </span>
              <small> / {t("checker-name-tooltip")}</small>{" "}
            </>
          }>
          {getFieldDecorator("name_to_check", {
            initialValue: this.props.data.name_to_check,
            rules: [
              {
                required: true,
                message: t("checker-name-error"),
                whitespace: false,
              },
            ],
          })(<Input placeholder="eg : My Wordpress" />)}
        </Form.Item>
        <Form.Item
          className="step-1"
          label={
            <>
              <span>{t("checker-route-label")}</span>
              <small> / {t("checker-route-tooltip")}</small>{" "}
            </>
          }>
          {getFieldDecorator("route_to_check", {
            initialValue: `${this.props.data.route_to_check}`,
            rules: [
              {
                pattern: "https?://.*..*",
                required: true,
                message: t("checker-route-error"),
                whitespace: false,
              },
            ],
          })(
            <Input
              suffix={this.props.suffixRouteInput()}
              onBlur={(e) => this.props.infoAboutRoute(e)}
              onChange={(e) => this.props.inputRouteChange(e.target.value)}
              placeholder="eg : https://website.com/ping or http://12.234.56.78:8080/pong"
            />
          )}
        </Form.Item>
        <Form.Item
          label={
            <>
              <span>
                {`${t("checker-checking-method-question")} : `}
                <Select
                  style={{ width: "auto" }}
                  size="small"
                  defaultValue={this.props.data.verification_method}
                  onChange={(value) => {
                    this.props.changeVerificationMethod(value);
                  }}>
                  {this.state.possibleVerificationMethods.map((m) => (
                    <Option key={m.value} value={m.value}>
                      {t(`checker-checking-method-${m.value}`)}
                    </Option>
                  ))}
                </Select>
                {this.props.data.verification_method === "200" && (
                  <>
                    {" "}
                    <Tooltip title={t("checker-checking-method-200-tt")}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </>
                )}
              </span>
              <>
                {this.props.data.verification_method === "kw" && (
                  <>
                    <br />
                    <br />
                    <>
                      <span>{t("checker-kw-label")} </span>
                      <small> / {t("checker-kw-tooltip")}</small>{" "}
                    </>
                  </>
                )}
              </>
            </>
          }>
          {getFieldDecorator("keywords_to_check", {
            initialValue: this.props.data.keywords_to_check,
            rules: [
              {
                validator: async (value) => {
                  if (
                    this.props.data.verification_method === "kw" &&
                    value.length === 0
                    //|| this.props.data.keywords_to_check.length
                  ) {
                    throw new Error(t("checker-kw-error"));
                  } else {
                    return;
                  }
                },
              },
            ],
          })(
            <Select
              mode="tags"
              tokenSeparators={[","]}
              style={{
                width: "100%",
                display: `${
                  this.props.data.verification_method !== "kw"
                    ? "none"
                    : "inline-block"
                }`,
              }}
              placeholder="eg : ping">
              {this.props.children}
              {this.props.fetchingTokens && (
                <Option
                  value={"vautours-fetching-kw"}
                  key={"vautours-fetching-kw"}>
                  <Spin spinning>Loading</Spin>
                </Option>
              )}
            </Select>
          )}
        </Form.Item>
        <Form.Item
          style={{
            display: `${
              this.props.data.verification_method === "dont"
                ? "none"
                : "inline-block"
            }`,
          }}
          label={
            <>
              <span>Interval</span> /
              <small>{t("checker-interval-tooltip")}</small>
            </>
          }>
          {getFieldDecorator("interval_to_check", {
            defaultValue: "60",
            initialValue: `${this.props.data.interval_to_check || "60"}`,
          })(
            <Radio.Group>
              <Radio.Button value="1">1min</Radio.Button>
              <Radio.Button value="30">30mins</Radio.Button>
              <Radio.Button value="60">hour</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>
      </>
    );
  }
}
