import React, {Component} from 'react';
import HomeOut from './pages/homeout.js';
import Home from './pages/home.js';
import auth0Client from './auth0.js';
import './App.scss';

class App extends Component {
  async componentDidMount() {
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      console.log(err);
      if (err.error !== 'login_required') console.log(err.error);
    }
  }

  render() {
    return <div>{auth0Client.isAuthenticated() ? <Home /> : <HomeOut />}</div>;
  }
}

export default App;
