import React, { Component } from "react";
import { Icon, Spin, Table, Tag, Divider } from "antd";

import { withTranslation } from "react-i18next";

//const { TextArea } = Input;

class DetailDataComp extends Component {
  render() {
    const { t } = this.props;
    const filter = [];
    const getContextFromStatus = (text) => {
      return "";
    };
    // making sure no doublon
    this.props.values.map((x) => {
      if (filter.filter((e) => e.value === x.failure_status).length === 0) {
        filter.push({ value: x.failure_status, text: x.failure_status });
      }
    });
    const columns = [
      {
        title: "Status",
        dataIndex: "failure_status",
        key: `failure_status`,
        filters: [...filter],
        onFilter: (value, record) => record.failure_status === value,
        //render: (text, record) =>  getContextFromStatus(text, record),
      },
      {
        title: "Date",
        dataIndex: "failure_time",
        key: "failure_time",
        render: (text, record) => `${record.date_l}`,
        sorter: (a, b) => a.failure_time - b.failure_time,
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <>
            {record.failure_file_url ? (
              <span
                style={{ cursor: "pointer" }}
                onClick={() =>
                  this.props.get_signed_url(record.uuid_to_fail, "failure")
                }>
                {this.props.arr_signed_url.includes(record.uuid_to_fail) ? (
                  <Spin />
                ) : (
                  <Icon type="file-text" theme="twoTone" />
                )}{" "}
                {t("download-file")}
              </span>
            ) : (
              <span>
                <Icon type="file-text" theme="twoTone" twoToneColor="#52c41a" />{" "}
                {t("no-download-file")}
              </span>
            )}
          </>
        ),
      },
    ];
    return (
      <div className="DetailDataFiles">
        <Table
          pagination={{ pageSize: 10, size:"small" }}
          columns={columns}
          dataSource={this.props.values}
        />
      </div>
    );
  }
}

export default withTranslation()(DetailDataComp);

//{this.props.values.length === 0 ? (
//<>
//<div className="detail-a-file no-pointer">
//<div className="detail-a-file-icon">
//<Icon
//type="file-text"
//theme="twoTone"
//twoToneColor="#52c41a"
///>
//</div>
//<ul>
//<li key={"a"}>
//{" "}
//<b>Status</b> - Good{" "}
//</li>
//<li key={"b"}> {t('detail-never-failed')}</li>
//</ul>
//</div>
//</>
//) : (
//<>
//{this.props.values
//.filter(x => x.failure_file_url !== "")
//.filter(x => !["2","1","0"].includes(x.failure_status))
//.reverse()
//.slice(0,5)
//.map((v, i) => (
//<div
//key={i}
//className="detail-a-file"
//onClick={() => this.props.get_signed_url(v.uuid_to_fail, 'failure')}>
//<div key={i} className="detail-a-file-icon">
//<Tooltip
//title={`${t("detail-historic-file-tt-first")} - ${
//v.date_l
//}. ${t("detail-historic-file-tt-last")}`}>
//{this.props.arr_signed_url.includes(v.uuid_to_fail) ? (
//<Spin />
//) : (
//<Icon type="file-text" theme="twoTone" />
//)}
//</Tooltip>
//</div>
//<ul key={v.uuid_to_fail}>
//<li key={i}>
//{" "}
//<b>Status</b> - {v.failure_status}{" "}
//</li>
//<li key={v.uuid_to_fail}>
//{" "}
//<b>Date</b> -{" "}
//{v.date.toLocaleDateString(this.props.i18n.language)}{" "}
//</li>
//</ul>
//</div>
//))}
//</>
//)}
