import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Auth from "./../auth0";
import { connect } from "react-redux";
import {
  //Badge,
  Select,
  Typography,
  //Divider,
  Layout,
  Icon,
  Steps
  //Popover,
} from "antd";

const { Option } = Select;
const { Title } = Typography;

const { Step } = Steps;

class Index extends Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    let uuid_to_watch = this.props.location.pathname.split("slack/")[1]; // /-slack/-uuid_to_watch
    let code = this.props.location.search
      .split("code=")[1] // ? -[code=]- the rest
      .split("&")[0]; // code -&- ?state=
    this.state = {
      successfullyAddedSlack: false,
      uuid_to_watch: uuid_to_watch,
      code: code
    };
    this.props.detail_watcher(this.state.uuid_to_watch);
  }

  handleSubmit = e => {
    e.preventDefault();
  };

  componentDidMount() {
    const redirect_uri =
      process.env.REACT_APP_AUTH0_CALLBACK_URL +
      "/slack/" +
      this.state.uuid_to_watch;
    this.props.get_slack_from_code({
      code: this.state.code,
      redirect_uri: redirect_uri,
      uuid_to_watch: this.state.uuid_to_watch
    });
  }

  componentDidUpdate() {
    if (
      !this.props.loading &&
      !this.state.successfullyAddedSlack &&
      this.props.state_code === "Slack-S-001"
    ) {
      this.setState({ successfullyAddedSlack: true });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <Layout className="CreateCheckItem">
        <Title level={2} style={{ marginBottom: 0 }}>
          Adding Slack
        </Title>
        <Title level={3} style={{ marginTop: 0 }}>
          Will now alert slack{"  "}
          <Icon type="slack" /> !
          <br />
        </Title>
        <Steps direction="vertical">
          <Step
            status="finish"
            title="Asking Slack for access"
            description="Access was successfully granted by Slack"
            icon={<Icon type="slack" />}
          />
          <Step
            status={this.props.loading ? "process" : "finish"}
            title="Asking our back-end"
            description="We are currently processing the request"
            icon={
              this.props.loading ? (
                <Icon type="loading" spin />
              ) : (
                <Icon type="cloud-server" />
              )
            }
          />
          <Step
            status={this.props.loading ? "wait" : "finish"}
            title={
              !this.props.loading
                ? this.state.successfullyAddedSlack
                  ? "success"
                  : "failure"
                : "Waiting"
            }
            description="Success you can close this window and go back to the other one"
            icon={
              <Icon
                type={
                  !this.props.loading
                    ? this.state.successfullyAddedSlack
                      ? "check-circle"
                      : "meh"
                    : "clock-circle"
                }
              />
            }
          />
        </Steps>
      </Layout>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    post_checker: data => dispatch({ type: "POST_CHECKER", payload: data }),
    detail_watcher: data => dispatch({ type: "DETAIL_WATCHER", payload: data }),
    update_watcher: data => dispatch({ type: "UPDATE_WATCHER", payload: data }),
    get_slack_from_code: data =>
      dispatch({ type: "GET_SLACK_FROM_CODE", payload: data }),
    get_watcher: () => dispatch({ type: "GET_WATCHER" })
  };
}

function mapStateToProps(state) {
  return {
    form_reset: state.res_req.form_reset,
    state_code: state.res_req.state_code,
    loading: state.res_req.loading,
    messageSuccess: state.res_req.messageSuccess,
    messageError: state.res_req.messageError,
    watchers: state.watcher.watchers,
    current_watcher: state.watcher.current_watcher
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Index));
