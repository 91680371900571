import React, { Component } from "react";
import i18n from "./../my-i18next.js";
import { Button, Icon, Select, Avatar, Typography } from "antd";
import { withTranslation } from "react-i18next";
//const { Option } = Select;

class UserInfo extends Component {
  render() {
    const { Paragraph } = Typography;
    const { Option } = Select;
    const { t } = this.props;
    const changeLanguage = (lng) => {
      i18n.changeLanguage(lng);
    };
    return (
      <div className="user-container">
        <div className="user-container-field">
          <div className="user-container-field-label">
            {t("settings-user-img-label")} :{" "}
          </div>
          <Avatar
            className="user-container-img"
            shape="square"
            size={64}
            src={this.props.user.picture}
          />
        </div>

        <div className="user-container-field">
          <div className="user-container-field-label">
            {t("settings-user-nickname-label")} :{" "}
          </div>
          {this.props.user.nickname}
        </div>
        <div className="user-container-field">
          <div className="user-container-field-label">
            {t("settings-user-lg-choose")} :{" "}
          </div>
          <Select
            onChange={(e) => changeLanguage(e)}
            defaultValue={this.props.i18n.language === "en" ? "en" : "fr"}
            style={{ width: 160 }}
            bordered={false}>
            <Option value="fr">{t("settings-user-lg-fr")}</Option>
            <Option value="en">{t("settings-user-lg-en")}</Option>
          </Select>
        </div>
        <div className="user-container-field">
          <div className="user-container-field-label">
            {t("settings-user-plan-label")} :{" "}
          </div>
          Basic
        </div>
      </div>
    );
  }
}

export default withTranslation()(UserInfo);
