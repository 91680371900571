import React from "react";
import API from "./../ApiLayer/index.js";
import Auth from "./../auth0";
import { connect } from "react-redux";
import { Button, Result } from "antd";
import { withTranslation, useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { Select, Icon, Steps, Tooltip } from "antd";

import CreateCheckerModal from "./../containers/CreateCheckerModal.js";

const { Option } = Select;

const MaxNotifiedBasic = process.env.REACT_APP_MAXNOTIFIEDBASIC;
const MaxNotifiedPro = process.env.REACT_APP_MAXNOTIFIEDPRO;

class NoCheckerYetCreateAChecker extends React.Component {
  state = {
    visible: true,
    currentStep: 0,
    children: [],
    httpsOn: true,
    fetchingTokens: false,
    foundKw: [],
    redirectTo: false,
    data: {
      verification_method: "200",
      interval_to_check: "60",
      name_to_check: "My First Checker",
      route_to_check: "https://",
      keywords_to_check: [],
      seo_should_to_check: true,
      ssl_should_to_check: false,
      dev_mode_to_check: false,
      name_to_watch: "Import - Founder CEO IT",
      textalert_to_watch: "We were unable to reach a checker.",
      array_to_notify: [
        { key_to_notify: Auth.getProfile().email, auth_to_notify: false },
      ],
    },
  };

  componentDidUpdate(prevProps) {
    // this is to track that a new checker has been added
    if (this.props.new_checker_uuid !== prevProps.new_checker_uuid) {
      this.reward.rewardMe();
      this.setState({
        currentStep: 3,
      });
    }
  }

  showModal = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    if (this.state.currentStep === 0) {
      this.setState({ visible: false });
      this.setState({ redirectTo: "/" });
    } else if (0 < this.state.currentStep < 3) {
      this.setState({
        currentStep: this.state.currentStep - 1,
      });
    }
    if (this.state.currentStep === 3) {
      this.setState({ visible: false });
      this.props.get_checker();
      this.setState({ redirectTo: "/" });
    }
  };

  handleCreate = () => {
    const { form } = this.formRef.props;
    // finishing first step
    if (this.state.currentStep === 0) {
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.setState({
          data: {
            ...this.state.data,
            ...values,
          },
          currentStep: this.state.currentStep + 1,
        });
      });
    }
    if (this.state.currentStep === 1) {
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        this.setState({
          data: {
            ...this.state.data,
            ...values,
          },
          currentStep: this.state.currentStep + 1,
        });
      });
    }
    if (this.state.currentStep === 2) {
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        // if the checker delete info to link it and not create
        if (values.uuid_to_watch) {
          delete values.name_to_watch;
          delete values.textalert_to_watch;
          delete values.key_to_notify;
        }
        // last check
        // if the 200 then have kw as status: 200
        let payload = {
          ...this.state.data,
          ...values,
        };
        console.log(payload);
        this.setState({ data: payload });
        this.props.post_checker(payload);
      });
    }
    if (this.state.currentStep === 3) {
      this.setState({ redirectTo: `/detail/${this.props.new_checker_uuid}` });
    }
  };

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  };

  changeVerificationMethod = (m) => {
    this.setState({
      data: {
        verification_method: m,
      },
    });
  };

  render() {
    if (this.state.redirectTo) {
      return <Redirect to={this.state.redirectTo} />;
    }
    const infoAboutRoute = async (e) => {
      this.setState({ badRoute: false });
      let oldRoute = this.state.data.route_to_check;
      let newRoute = e.target.value;
      if (newRoute !== oldRoute && newRoute.includes(".")) {
        this.setState({ fetchingTokens: true });
        try {
          let response = await API.getInfoAboutRoute(newRoute);
          if (response.error_status) {
            this.setState({
              badRoute: true,
              errorToolTip: response.error_tooltip,
            });
          }
          if (response.tokens.length > 0) {
            this.setState({
              foundKw: response.tokens,
            });
          }
        } catch (e) {
          /* handle error */
        }
        this.setState({ fetchingTokens: false });
      }
    };
    const inputRouteChange = (r) => {
      this.setState({ httpsOn: r.includes("https://"), foundKw: [] });
    };
    const { t } = this.props;

    let children = this.state.foundKw
      // if the user check with a 200
      .map((kw) => (
        <Option value={kw} key={kw}>
          {kw}{" "}
          <span className="found-on-your-site">
            {t("checker-found-on-your-site")}
          </span>
        </Option>
      ));

    const suffixRouteInput = () => {
      let suffix = "";
      if (this.state.badRoute) {
        suffix = (
          <Tooltip title={t(this.state.errorToolTip)} placement="left">
            <Icon style={{ color: "red" }} type="warning" />
          </Tooltip>
        );
      }
      if (this.state.fetchingTokens) {
        suffix = <Icon style={{ color: "green" }} type="loading-o" spin />;
      }
      return suffix;
    };

    const maxNotified = () => {
      if (
        this.props.user.user_plan === undefined ||
        this.props.user.user_plan === "b"
      ) {
        return MaxNotifiedBasic;
      }
      return MaxNotifiedPro;
    };
    const getDividerText = () => {
      return ["add-ressource", "add-feature", "add-watcher"][
        this.props.currentStep
      ];
    };

    const { visible, onCancel, onCreate, form } = this.props;
    return (
      <div>
        <CreateCheckerModal
          {...this.props}
          data={this.state.data}
          new_checker_uuid={this.props.new_checker_uuid}
          wrappedComponentRef={this.saveFormRef}
          currentStep={this.state.currentStep}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
          getDividerText={getDividerText}
          suffixRouteInput={suffixRouteInput}
          inputRouteChange={inputRouteChange}
          infoAboutRoute={infoAboutRoute}
          maxNotified={maxNotified}
          children={children}
          fetchingTokens={this.state.fetchingTokens}
          changeVerificationMethod={this.changeVerificationMethod}
          loading={this.props.loading}
          rewardRef={(ref) => {
            this.reward = ref;
          }}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    get_checker: () => dispatch({ type: "GET_CHECKER" }),
    get_watcher: () => dispatch({ type: "GET_WATCHER" }),
    reset_checker: () => dispatch({ type: "RESET_CHECKER" }),
    reset_watcher: () => dispatch({ type: "RESET_WATCHER" }),
    healthy_checker: (uuid) =>
      dispatch({
        type: "HEALTHY_CHECKER",
        payload: { uuid_to_check: uuid, status_to_check: true },
      }),
    post_checker: (data) => dispatch({ type: "POST_CHECKER", payload: data }),
    update_checker: (data) =>
      dispatch({ type: "UPDATE_CHECKER", payload: data }),
    delete_checker: (uuid) =>
      dispatch({ type: "DELETE_CHECKER", payload: uuid }),
  };
}

function mapStateToProps(state) {
  return {
    watchers: state.watcher.watchers,
    checkers: state.checker.checkers,
    loading: state.res_req.loading,
    new_checker_uuid: state.res_req.new_checker_uuid,
    user: state.user,
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NoCheckerYetCreateAChecker));
