import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NewsCardDetail from "./NewsCardDetail.js";
import Ads from "./AdsComponent.js";
import { AdManagerComponent } from "./AdManagerComponent.js";
import { Typography, Icon, Button } from "antd";
const { Title, Paragraph } = Typography;

const NewsFeed = (props) => {
  const { t } = useTranslation();
  const news = [
    {
      title: "hello",
      body:
        " Amet optio dignissimos nulla doloremque esse? Neque nostrum non perferendis maiores praesentium? Quas iusto error minima suscipit dolor. Dolorem aliquid culpa sed itaque eaque? Atque atque molestiae officiis obcaecati quisquam? Rerum nisi nobis maiores nemo perferendis? Ipsa velit tempore quisquam commodi tempore officia. Dolores perspiciatis ad reprehenderit ex asperiores. In.",
    },
    {
      title: "hello",
      body:
        " Amet optio dignissimos nulla doloremque esse? Neque nostrum non perferendis maiores praesentium? Quas iusto error minima suscipit dolor. Dolorem aliquid culpa sed itaque",
    },
  ].map((aNew) => <NewsCardDetail {...props} newInfo={aNew} />);

  const isInterested = localStorage.getItem("interested_in_news");
  const [isInterestedState, changeisInterestedState] = useState(false);
  return (
    <div style={{ padding: "20px" , maxWidth:"900px"}} className="NewsContainer">
      <Title level={2}>{t("interested_in")}</Title>
      <Title level={4}>{t("desc_news_1")}</Title>
      <Paragraph>{t("desc_news_2")}</Paragraph>
      <Paragraph>
        {!isInterested && !isInterestedState ? (
          <Button
            className="btn"
            onClick={() => {
              changeisInterestedState(true);
              localStorage.setItem("interested_in_news", true);
            }}>
            {" "}
            <Icon type="heart" />
            {t("i_would_be_interested")}{" "}
          </Button>
        ) : (
          <Button className="btn" type="primary">
            {" "}
            <Icon type="star" />
            {t("i_am_interested")}{" "}
          </Button>
        )}
      </Paragraph>
      <small>{t("warning_vote")}</small>
    </div>
  );
};

export default NewsFeed;
