import Auth from "./../../auth0";

const watcher_is = {
  watchers: [],
  current_watcher: {
    array_to_notify: [],
    textalert_to_watch: " tempora Doloremque aliquam molestias?"
  }
};

const watcher_reducer = (state = watcher_is, action) => {
  switch (action.type) {
    case "RESET_WATCHER":
      let newrState = Object.assign({}, state, {
        current_watcher: {
          name_to_watch: "",
          array_to_notify: [
            { key_to_notify: Auth.getProfile().email, auth_to_notify: false }
          ],
          textalert_to_watch: ""
        }
      });
      return newrState;

    case "GET_WATCHER_SUCCESS":
      let newgState = Object.assign({}, state, {
        watchers: [...action.payload]
      });
      return newgState;

    //case "POST_WATCHER_SUCCESS":
      //break

    case "UPDATE_WATCHER_SUCCESS":
      let uWatcher = action.payload.data;
      let newuState = Object.assign({}, state, {
        current_watcher: uWatcher
      });
      return newuState;

    case "DETAIL_WATCHER_SUCCESS":
      let dWatcher = { ...action.payload };
      let newdState = Object.assign({}, state, {
        current_watcher: { ...dWatcher }
      });
      return newdState;

    default:
      return state;
  }
};

export default watcher_reducer;
