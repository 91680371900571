export const userInfo = {
  en: {
    "settings-user-title": "User Profile",
    "settings-user-img-label": "Your Avatar",
    "settings-user-nickname-label": "Your Nickname",
    "settings-user-lg-choose": "Pick a language",
    "settings-user-lg-fr": "Français",
    "settings-user-lg-en": "English",
    "settings-user-plan-label": "Your Plan",

    "settings-ressource-title": "Your Watchers & Notifieds",
    "settings-ressource-delete": "Delete",
    "settings-ressource-invite": "Ask to join",

    "settings-ressource-watcher-name-label": "Name",
    "settings-ressource-watcher-status-label": "Authorization",
    "settings-ressource-watcher-route-label": "Route",
    "settings-ressource-watcher-status-healthy": "100% Authorized",
    "settings-ressource-watcher-status-failure": "Missing Authorization(s)",

    "settings-ressource-notified-name-label": "Name",
    "settings-ressource-notified-auth-label": "Auth",
    "settings-ressource-notified-auth-tooltip":
      "We need to ask permission before sending an alert. A red status means the notified has not granted it yet.",
    "settings-ressource-notified-action-label": "Action",
    "settings-ressource-notified-unauth": "Not yet",
    "settings-ressource-notified-auth": "Authorized"
  },
  fr: {
    "settings-user-title": "Profil Utilisateur",
    "settings-user-img-label": "Votre Avatar",
    "settings-user-nickname-label": "Votre Pseudo",
    "settings-user-lg-choose": "Choisir la langue",
    "settings-user-lg-fr": "Français",
    "settings-user-lg-en": "English",
    "settings-user-plan-label": "Votre Plan",

    "settings-ressource-title": "Vos Watchers & Notifiés",
    "settings-ressource-delete": "Supprimer",
    "settings-ressource-invite": "Demander à joindre",

    "settings-ressource-watcher-name-label": "Nom",
    "settings-ressource-watcher-status-label": "Authorisation",
    "settings-ressource-watcher-route-label": "Action",
    "settings-ressource-watcher-status-healthy": "100% Autorisé",
    "settings-ressource-watcher-status-failure": "Manque Autorisation(s)",

    "settings-ressource-notified-name-label": "Nom",
    "settings-ressource-notified-auth-label": "Auth",
    "settings-ressource-notified-auth-tooltip":
      "Nous devons demander des autorisations avant d'envoyer une alerte. Un statut rouge signifie que le notifié ne l'a pas encore accordé.",
    "settings-ressource-notified-action-label": "Action",
    "settings-ressource-notified-unauth": "Pas encore",
    "settings-ressource-notified-auth": "Autorisé"
  }
};
