export const home = {
  en: {
    "list-checker": "List Checkers",
    "add-checker": "Add a Checkers",
    "list-settings": "Settings",
    "log-out":"Log-Out",
    "ssl-day-short":"d",
    "ssl-day-long":"day(s)",
    "ssl-tooltip": "Your SSL certificate (HTTPS) is valid for another",
    "ssl-tooltip-not-valid": "Your SSL certificate is not valid anymore. You might want to renew it."
  },
  fr: {
    "list-checker": "Vos Checkers",
    "add-checker": "Ajouter un Checker",
    "list-settings": "Paramètres",
    "log-out":"Se Déconnecter",
    "ssl-day-short":"j",
    "ssl-day-long":"jour(s)",
    "ssl-tooltip": "Votre certificat SSL (HTTPS) est valide pour encore",
    "ssl-tooltip-not-valid": "Votre certificat SSL n'est plus valide!"
  }
};
