import React, { Component } from "react";
import Reward from "react-rewards";
//import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { message, Badge, Icon } from "antd";

class WidgetCTA extends Component {
  constructor(props) {
    super(props);
  }
  handleClick = () => {
    const { t } = this.props;
    this.reward.punishMe();
    message.info(t("only-in-pro-alert"));
  };

  render() {
    const { t } = this.props;
    return (
      <div className="widget-cta">
        <Reward
          ref={(ref) => {
            this.reward = ref;
          }}
          type="emoji">
          <div className="add-alert-cta" onClick={this.handleClick}>
            <span className="widget-cta-text">{t("add-an-alert")}</span>
            <span className="widget-cta-icon">
              <Icon theme="twoTone" twoToneColor="#FFF" type="alert" />
            </span>
          </div>
        </Reward>
      </div>
    );
  }
}

export default withTranslation()(WidgetCTA);
