import React, { Component } from "react";
import { message, Typography } from "antd";
import WidgetCTA from "./../Widget/WidgetCTA.js";
import RequestSSL from "./../Widget/RequestSSL.js";

import { withTranslation } from "react-i18next";
const { Paragraph } = Typography;

//const { TextArea } = Input;

class SSLMonitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      daysRemeaning: "---",
      ssl_valid: true,
      from: "--/--/--",
      to: "--/--/--",
      domain: ["--------.net"],
      ssl_not_yet_loaded_message_sent: false,
    };
  }
  componentDidMount() {
    const { t } = this.props;
    if (
      this.props.si !== undefined && 
      this.props.si.ssl_valid_for !== undefined && 
      this.state.daysRemeaning === "---") {
      let ssl_valid = true;
      let ssl_valid_from = new Date(this.props.si.ssl_valid_from);
      let ssl_valid_to = new Date(this.props.si.ssl_valid_to);
      let daysRemaining = new Date(this.props.si.ssl_valid_to) - new Date();
      if (daysRemaining > 0) {
        daysRemaining = Math.floor(daysRemaining / 86400000);
        // ms to days
      } else {
        daysRemaining = 0;
        // making sure I don't have a neg number of days
        ssl_valid = false;
      }
      this.setState({
        daysRemeaning: daysRemaining,
        from: ssl_valid_from.toLocaleDateString(),
        to: ssl_valid_to.toLocaleDateString(),
        ssl_valid: ssl_valid,
        domain: [...this.props.si.ssl_valid_for],
      });
    } else if (
      this.state.daysRemeaning === "---" &&
      !this.state.ssl_not_yet_loaded_message_sent
    ) {
      this.setState({ ssl_not_yet_loaded_message_sent: true });
      message.info(t("ssl_not_yet_loaded"));
    }
  }
  render() {
    const { t } = this.props;
    console.log(this.props)
    return (
      <div className="DetailSSLMonitoring">
        <div className={`ssl-card ${!this.state.ssl_valid && "ssl_unvalid"}`}>
          <WidgetCTA />
          <RequestSSL uuid_to_check={this.props.uuid_to_check} />
          <div className="ssl-top-card">SSL Certificate 🔒</div>
          <div className="ssl-day-card">
            Your SSL is valid for <span>{this.state.daysRemeaning} days</span>
          </div>
          <div className="ssl-valid-card">
            the certifiate is valid from <span>{this.state.from}</span> to{" "}
            <span>{this.state.to}</span>
          </div>
          <div className="ssl-domain-card">
            <Paragraph ellipsis={{ rows: 1, expandable: true }}>
              The certicate covers{" "}
              {this.state.domain.map((d) => (
                <span key={d}>{d}, </span>
              ))}
            </Paragraph>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(SSLMonitoring);
