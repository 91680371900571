import React, { Component } from "react";
import { Modal, Button } from "antd";

class AskModal extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let uuid = this.props.uuid;
    return (
      <Modal
        autoFocusButton="ok"
        title={this.props.titleModal}
        visible={this.props.showModal}
        onOk={() => this.props.handleOk(uuid)}
        onCancel={() => this.props.handleCancel()}
        okText={this.props.okText}
        cancelText={this.props.cancelText}
        okType={this.props.okType}>
        {this.props.contentModal}
      </Modal>
    );
  }
}

export default AskModal;
